import colors from '../../assets/colors.json'
const system = {
  state: {
    SubPopup: false,
    SubPopupTitle: '',
    themeColors: colors,
    showLoader: false,
    loaderMessage: '',
    setLayout: false,
    showAlert: false,
    alertMessage: '',
    alertColor: '',
    companyLogo: '',
    systemTopBarColor: 'primary',
    microEstPath: '',
    localRepoPath: '',
    showNotification: false,
    menuList: [],
    dateTimeFormat: '',
    uploadProgressValue: 0,
    downloadFileMessage: '',
    showDownloadLoader: false,
    cancelDownloadRequestSource: null,
    showUploadLoader: false,
    uploadFileMessage: '',
    calendarDayHighlightValue: '',
    firstDayOfTheWeek: 1,
    isApiLoading: false,
    globalEmailDialog: false,
    moduleName: '',
    entitySlug: '',
    detailId: 0,
    templateData: {},
    showIpExpirePopup: false
  },

  mutations: {
    SET_SUBSCRIBE_DIALOG: (state, subscripeObj) => {
      console.log('subscripeObj', subscripeObj)
      state.SubPopup =  subscripeObj.status
      state.SubPopupTitle = subscripeObj.title
    },
    SET_FIRST_DAY_OF_WEEK: (state, value) => {
      state.firstDayOfTheWeek = value
    },
    SET_CALENDAR_HIGHLIGHT_DAY: (state, value) => {
      state.calendarDayHighlightValue = value
    },
    SET_DOWNLOAD_LOADER: (state, status) => {
      state.showDownloadLoader = status
    },
    SET_UPLOAD_LOADER: (state, status) => {
      state.showUploadLoader = status
    },
    SET_CANCEL_REQUEST: (state, cancelSource) => {
      state.cancelDownloadRequestSource = cancelSource
    },
    SET_DOWNLOAD_MESSAGE: (state, message) => {
      state.downloadFileMessage = message
    },
    SET_UPLOAD_MESSAGE: (state, message) => {
      console.log('Message 1', message)
      state.uploadFileMessage = message
    },
    SET_UPLOAD_PROGRESS: (state, value) => {
      state.uploadProgressValue = value
    },
    SET_SHOW_NOTIFICATION: (state, show) => {
      state.showNotification = show
      // state.loaderMessage = loaderObj.message
    },
    SET_LAYOUT: (state, setLayoutVlue) => {
      state.setLayout = setLayoutVlue
    },
    SET_SHOW_LOADER: (state, loaderObj) => {
      state.showLoader = loaderObj.status
      state.loaderMessage = loaderObj.message
    },
    SET_SHOW_ALERT: (state, alertObj) => {
      state.showAlert = alertObj.showAlert
      state.alertMessage = alertObj.message
      state.alertColor = alertObj.color
    },
    SET_COMPANY_LOGO: (state, url) => {
      state.companyLogo = url
    },
    SET_TOPBAR_COLOR: (state, color) => {
      state.systemTopBarColor = color
    },
    SET_MENULIST: (state, menuList) => {
      state.menuList = menuList
    },
    SET_MICROESTPATH: (state, microEstPath) => {
      state.microEstPath = microEstPath
    },
    SET_LOCALREPOPATH: (state, localRepoPath) => {
      state.localRepoPath = localRepoPath
    },
    SET_DATE_TIME_FORMAT: (state, format) => {
      state.dateTimeFormat = format
    },
    SET_IS_API_LOADING: (state, data) => {
      state.isApiLoading = data
    },
    SET_GLOBAL_EMAIL_DIALOG: (state, globalEmailDialogObj) => {
      console.log(globalEmailDialogObj)
      state.globalEmailDialog = globalEmailDialogObj.status
      state.moduleName = globalEmailDialogObj.moduleName
      state.entitySlug = globalEmailDialogObj.entitySlug
      state.detailId = globalEmailDialogObj.detailId
      state.templateData = globalEmailDialogObj.templateData
    },
    SET_SHOW_IP_EXPIRED_POPUP: (state, ipExpired) => {
      state.showIpExpirePopup = ipExpired.showIpExpirePopup
    }
  },
  actions: {
    ShowSubPopup ({commit}, subscripeObj) {
      commit('SET_SUBSCRIBE_DIALOG', subscripeObj)
    },
    SetLayoutVal ({commit}, setLayoutVlue) {
      commit('SET_LAYOUT', setLayoutVlue)
    },
    SetLoader ({commit}, loaderObj) {
      commit('SET_SHOW_LOADER', loaderObj)
    },
    SetNotification ({commit}, show) {
      commit('SET_SHOW_NOTIFICATION', show)
    },
    SetAlert ({commit}, alertObj) {
      commit('SET_SHOW_ALERT', alertObj)
    },
    // SetAlert ({commit}, alertObj) {
    //   commit('SET_SHOW_ALERT', alertObj)
    // },
    SetCompanyLogo ({commit}, url) {
      commit('SET_COMPANY_LOGO', url)
    },
    SetTopBarColor ({commit}, color) {
      commit('SET_TOPBAR_COLOR', color)
    },
    SetMenuList ({commit}, menuList) {
      commit('SET_MENULIST', menuList)
    },
    SetMicroEstPath ({commit}, microEstPath) {
      commit('SET_MICROESTPATH', microEstPath)
    },
    SetLocalRepoPath ({commit}, localRepoPath) {
      commit('SET_LOCALREPOPATH', localRepoPath)
    },
    SetUploadProgressValue ({commit}, value) {
      commit('SET_UPLOAD_PROGRESS', value)
    },
    SetDownloadMessage ({commit}, message) {
      commit('SET_DOWNLOAD_MESSAGE', message)
    },
    SetDownloadLoader ({commit}, status) {
      commit('SET_DOWNLOAD_LOADER', status)
    },
    SetUploadMessage ({commit}, message) {
      console.log('Message 0', message)
      commit('SET_UPLOAD_MESSAGE', message)
    },
    SetUploadLoader ({commit}, status) {
      commit('SET_UPLOAD_LOADER', status)
    },
    CancelDownloadRequest ({commit}, cancelSource) {
      commit('SET_CANCEL_REQUEST', cancelSource)
    },
    SetDateTimeFormat ({commit}, format) {
      commit('SET_DATE_TIME_FORMAT', format)
    },
    SetCalendarHightlightDays ({commit}, value) {
      commit('SET_CALENDAR_HIGHLIGHT_DAY', value)
    },
    SetFirstDayOfWeek ({commit}, value) {
      commit('SET_FIRST_DAY_OF_WEEK', value)
    },
    SetIsApiRunning ({commit}, data) {
      commit('SET_IS_API_LOADING', data)
    },
    SetGlobalEmailDialog ({commit}, globalEmailDialogObj) {
      commit('SET_GLOBAL_EMAIL_DIALOG', globalEmailDialogObj)
    },
    SetShowIpExpiredPopup ({commit}, data) {
      commit('SET_SHOW_IP_EXPIRED_POPUP', data)
    }
  }
}

export default system
