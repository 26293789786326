<template>
  <div class="mt-4">
    <div
      :class="` rounded
        bg-white min-w-max
        border border-primary
        text-primary
        px-4 xl:px-4
        ${smallSize ? 'py-2' : 'py-2' }
        shadow-mds
      `"
      role="alert"
    >
      <div class="flex items-center">
        <div class="py-1">
          <svg
            class="fill-current h-6 w-6 text-primary mr-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
            />
          </svg>
        </div>
        <div class="w-full">
          <p class="xl:text-center heading-6">{{ alertMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  props: ["alertMessage", "smallSize"],
  watch: {},
  mounted() {},
};
</script>
<style>
</style>
