<template>
  <div class="card bg-white rounded-xl p-2">
    <div>
      <div style="overflow-y: auto; white-space: nowrap;border-collapse: collapse; " class="relative" v-if="isFirstLoad">
        <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
          <div class="table_col2 uppercase ">Customer Name</div>
          <div class="table_col uppercase ">Notes</div>
          <div class="card_col uppercase ">Customers:</div>
        </div>
        <div class=" flex table_containder min-w-min" style="flex-flow:wrap;">
          <div class="flex p-2 rounded w-full min-w-min my-1 border-b border-gray1 hover:bg-gray-100 cursor-pointer" @click="showJobDetail(data.customerId)" v-for="(data, index) in customerList" :key="index">
            <div class="flex">
              <div class="table_col2 text-gray4 heading-6">
                <span v-if="data.companyName !== ''" v-html="matchFind(data.companyName)"></span>
                <span v-else>-</span>
              </div>
              <div class="table_col text-gray4 heading-6">
                <span class="overFlowParaA" v-if="data.hotNotes !== ''" v-html="matchFind(data.hotNotes)"></span>
                <span v-else>-</span>
              </div>
            </div>
            <div class="border border-gray2 p-2 rounded card card_col">
              <div v-for="(dataA, indexB) in data.contactList.contacts" :key="indexB" class="flex hover:bg-gray-100 group cursor-pointer " :class="data.contactList.contacts > 1 ? 'py-0.5' : ''">
                  <div>
                    <p class=" text-text2"><span v-html="matchFind(dataA.fn)"></span> <span v-html="matchFind(dataA.ln)"></span></p>
                    <p class=" flex flex-wrap heading-6  text-text1">
                      <span class="pl-1">
                        <span v-if="dataA.pn !== ''" class="text-gray3">P:</span> {{dataA.pn| phone}} 
                        <span class="text-gray3" v-if="dataA.on !== ''">,</span>
                      </span>
                      <span v-if="dataA.on !== ''" class="pl-1">
                          <span class="text-gray3"> O:</span> {{dataA.on| phone}}
                      </span>
                      <span class="text-gray3" v-if="dataA.on === '' && dataA.pn === ''" >-</span>
                    </p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="customerList.length === 0 && isFirstLoad">
        <NoRecords :alertMessage="` No Customer found`" />
      </div>
    </div>
  </div>
</template>
<script>
import NoRecords from '@/View/components/noRecords.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
  components: {
    NoRecords
  },
  data() {
    return {
      customerList: [],
      searchValue: '',
      isFirstLoad: false,
    };
  },
  watch: {
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.$root.$on("customerSearchingCall", (response) => {
      this.searchValue = response
      this.customerListApi (response)
    });
  },
  methods: {
    customerListApi (data) {
      this.isFirstLoad = false
      MyJobApp.GlobalCustomerSearch(
        0,
        500,
        data,
        response => {
          let customerList = response.Data.tableRow !== null ? response.Data.tableRow : []
          for (let index = 0; index < customerList.length; index++) {
            customerList[index].contactList = JSON.parse(customerList[index].contactList)
          }
          this.customerList = customerList
          this.$root.$emit('custSearchingCount', response.Data.count)
          this.isFirstLoad = true
        },
        (error) => {
          this.isFirstLoad = true
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    resizeWindowHandler() {
      this.screenWidth = window.innerWidth
    },
    showJobDetail (Id) {
      this.$router.push({name: 'customerDetails', params: {customerId: Id}})
      this.$root.$emit('closeGlobalSearchDialog')
    },
    matchFind (str) {
      if (this.searchValue === null || this.searchValue === undefined || this.searchValue === ' ') {
        return
      }
      let searchStrArray = this.searchValue.split(' ')
      let replceString = str.trim()
      for (let i = 0; i < searchStrArray.length; i++) {
        if (searchStrArray[i].trim() !== '' && searchStrArray[i].trim() !== 'em') {
          replceString = replceString.replace(new RegExp(searchStrArray[i], 'gi'), match => {
            return '<em style="background-color:#78c8f294">' + match + '</em>'
          })
        }
      }
      return replceString
    }
  },
  beforeDestroy() {
    this.$root.$off('customerSearchingCall')
  },
};
</script>
<style scoped>
.table_col {
  min-width: 300px !important;
  width: 400px !important;
  max-width: 400px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.card_col {
  min-width: 300px !important;
  width: 500px !important;
  max-width: 500px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.table_col2 {
  min-width: 130px !important;
  width: 210px !important;
  max-width: 210px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.table_col3 {
  min-width: 130px !important;
  width: 220px !important;
  max-width: 220px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
  
  