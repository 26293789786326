<template>
   <div class="popup_overlay relative px-4">
       <div style="width: 470px;" class="custom_dialog rounded-xl h-min p-6" v-click-outside="closepopup">
        <div  class="mb-5 flex items-start">
          <p class="text-gray4 heading-1 flex"><i class="fa-solid fa-triangle-exclamation fa-fade"></i></p>
          <p class="heading-4 text-gray4 pl-2">{{$store.getters.SubPopupTitle}}</p>
        </div>
          <div class="flex gap-2 justify-end w-full">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Close'" @buttonAction="closepopup"/>
          </div>
      </div>
    </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'

export default {
  data() {
    return {
    };
  },
  components: {
    Button,
  },
  watch: {},
  mounted() {},
  methods: {
    isNumber (event) {
      console.log('event', event)
    },
    closepopup () {
      this.$store.dispatch('ShowSubPopup', {status: false, title: ''})
    },
  }
};
</script>
<style>
</style>
