<template>
<svg :width="width" :height="height" :fill="color" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
	<path d="M5.207 8.471l7.146 7.147-0.707 0.707-7.853-7.854 7.854-7.853 0.707 0.707-7.147 7.146z" />
</svg>
</template>
<script>
export default {
  name: 'rightArrowIconSvg', // vue component name
  props: {
    color: {
      type: String,
      default: 'gray'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
