<template>
  <div class="card bg-white rounded-xl p-2">
    <div>
      <div style="overflow-y: auto; white-space: nowrap;border-collapse: collapse; " class="relative" v-if="isFirstLoad">
        <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center" v-if="!mobileView">
          <div class="lead_name_col uppercase ">TRANSACTION ID</div>
          <div class="lead_name_col uppercase ">PAYMENT METHOD</div>
          <div class="lead_name_col uppercase ">AMOUNT</div>
          <div class="lead_name_col uppercase">DATE</div>
          <div class="lead_name_col uppercase">NOTE</div>
        </div>
        <div class=" flex table_containder min-w-min" style="flex-flow:wrap;" v-if="!mobileView">
          <div class="flex p-2  rounded min-w-min w-full my-1 border-b border-gray1 hover:bg-gray-100 cursor-pointer" @click="showJobDetail(data.paymentId)" v-for="(data, index) in paymentList" :key="index">
            <div class="lead_name_col text-gray4 heading-6">
              <span v-if="data.transactionId !== ''" v-html="'#'+ matchFind(data.transactionId)"></span>
              <span v-else>-</span>
            </div>
            <div class="lead_name_col text-gray4 heading-6">
              <span v-if="data.paymentMethodName !== ''" v-html="matchFind(data.paymentMethodName)"></span>
              <span v-else>-</span>
            </div>
            <div class="lead_name_col text-gray4 heading-6" >
              <span v-if="data.paymentAmount !== ''">{{ data.paymentAmount | amountFormaterWithToFix}}</span>
              <span v-else>-</span>
            </div>
            <div class="lead_name_col text-gray4 heading-6">
              <span>{{ data.addedDate | formatDataeTime}}</span>
            </div>
            <div class="lead_name_col text-gray4 heading-6">
              <span class="overFlowParaA" v-html="matchFind(data.paymentNotes)"></span>
            </div>
          </div>
        </div>
        <div v-if="mobileView">
            <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="isFirstLoad">
              <div v-for="(data, index) in paymentList" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="showJobDetail(data.paymentId)">
                <div class="">
                  <div class="flex justify-between items-center">
                    <p class="text-gray4 heading-6">{{ data.addedDate | formatDataeTime}}</p>
                    <p class="text-gray4 heading-5 font-semibold">
                      <span v-if="data.paymentAmount !== ''">{{ data.paymentAmount | amountFormaterWithToFix}}</span>
                    </p>
                  </div>
                  <p class="text-gray4 heading-6" v-html="matchFind(data.paymentMethodName)"></p>
                  <p class="text-gray4 heading-6 overFlowParaA" v-html="matchFind(data.paymentNotes)"></p>
                  </div>
              </div>
            </div>
        </div>
      </div>
      <div v-if="paymentList.length === 0 && isFirstLoad">
        <NoRecords :alertMessage="` No Payment found`" />
      </div>
    </div>
  </div>
</template>
<script>
import NoRecords from '@/View/components/noRecords.vue'
var moment = require('moment')
import MyJobApp from '@/api/MyJobApp.js'
export default {
  components: {
    NoRecords
  },
  data() {
    return {
      paymentList: [],
      searchValue: '',
      mobileView: false,
      isFirstLoad: false,
    };
  },
  watch: {
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.$root.$on("paymentSearchingCall", (response) => {
      this.searchValue = response
      this.paymentListApi (response)
    });
  },
  methods: {
    paymentListApi (data) {
      this.isFirstLoad = false
      MyJobApp.GlobalPaymentSearch(
        0,
        500,
        data,
        response => {
          this.paymentList = response.Data.tableRow !== null ? response.Data.tableRow : []
          this.$root.$emit('paymentSearchingCount', response.Data.count)
          this.isFirstLoad = true
        },
        (error) => {
          this.isFirstLoad = true
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    dateFilter (dateTime, value) {
      let checkDate = new Date(dateTime)
      let timeValue = moment(checkDate, 'ddd DD-MMM-YYYY, hh:mm a').format('hh:mm a')
      let todayDate = new Date().setHours(0, 0, 0, 0)
      var fullDate = moment(dateTime).format('MMM DD, ')
      let dayName = moment(dateTime).format('ddd')
      dateTime = new Date(dateTime).toUTCString()
      let requestDt = dateTime
      requestDt = new Date(requestDt).setHours(0, 0, 0, 0)
      if (value === 'scheduleDate') {
        return fullDate + ' ' + dayName
      } else if (value === 'scheduleTime') {
        return timeValue
      } else {
        if (moment(todayDate).diff(requestDt, 'days') === 0) {
          return 'Today ' + timeValue
        }
        let difDay = moment(todayDate).diff(requestDt, 'days')
        if (difDay > 1) {
          return fullDate + ' ' + timeValue
        }
        if (difDay === 1) {
          return 'Yesterday ' + timeValue
        }
      }
    },
    resizeWindowHandler() {
      if (window.innerWidth < 1194) {
        this.mobileView = true
      } else {
        this.mobileView = false
      }
    },
    showJobDetail (id) {
      this.$router.push({name: 'paymentDetails', params: {paymentId: id}})
      this.$root.$emit('closeGlobalSearchDialog')
    },
    matchFind (str) {
      if (this.searchValue === null || this.searchValue === undefined || this.searchValue === ' ') {
        return
      }
      let searchStrArray = this.searchValue.split(' ')
      let replceString = str.trim()
      for (let i = 0; i < searchStrArray.length; i++) {
        if (searchStrArray[i].trim() !== '' && searchStrArray[i].trim() !== 'em') {
          replceString = replceString.replace(new RegExp(searchStrArray[i], 'gi'), match => {
            return '<em style="background-color:#78c8f294">' + match + '</em>'
          })
        }
      }
      return replceString
    }
  },
  beforeDestroy() {
    this.$root.$off('paymentSearchingCall')
  },
};
</script>
<style scoped>
.lead_name_col {
  min-width: 220px !important;
  width: 220px !important;
  max-width: 220px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
  
  