<template>
  <div id="app">
    <div class="flex bg-screen_bg">
      <div class="" v-if="orgDetail !== null">
        <div class="topAlert text-center bg-error text-white font-semibold flex items-center justify-center fixed top-0 h-5 rounded-sm z-40 min-w-full">
        <p>You are login as Super Admin ({{orgDetail.user}})</p>
        </div>
      </div>
      <div id="overlay"></div>
      <div id="letf_nav_container" v-if="!hideLayout" class="letf_nav_box bg-screen_bg">
        <LeftNavbar />
      </div>
      <div id="layoutContent" class="w-full min-h-screen overflow-hidden">
        <div v-if="!hideLayout" class="top_nav_box "><TopNavbar :countryCode="cCode" /></div>
        <div :class="hideLayout ? '' : 'lg:pr-4'"  class=" px-4 lg:px-0 mainDiv">
          <router-view />
        </div>
      </div>
    </div>
    <div v-if="isDisableScreen" class="popup_overlay relative px-4">
      <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
        <div  class="mb-5 font-semibold">
          <p class="heading-3 text-text1">Your {{this.planObj.isFree ? 'Free/trial' : this.planObj.currentPlanName}} has ended</p>
        </div>
        <div  class="mb-5 font-semibold">
          <p class="heading-5 text-text2 mb-4">Your {{this.planObj.isFree ? 'Free/trial' : this.planObj.currentPlanName}} period has expired on {{this.planObj.currentPlanEndOn | popupDateFilter}}, you can countinue using the app after you subscribe, please click below to subscribe.</p>
        </div>
        <div class="flex justify-center">
          <button @click="openPlanDetail()" class="card bg-primary text-white px-5 py-3 text-center rounded-lg hover:opacity-80 w-96 font-semibold">Subscribe Now</button>
        </div>
        <div class="flex justify-center pt-4">
          <button @click="backLogin()" class="card bg-secondary text-white px-5 py-3 text-center rounded-lg hover:opacity-80 w-52 font-semibold">Logout</button>
        </div>
      </div>
    </div>
    <SubPopup v-if="$store.getters.SubPopup"></SubPopup>
    <loader
        v-if="$store.getters.showLoader"
        v-bind:data="$store.getters.loaderMessage"
      ></loader>
      <snak-bar
        v-if="$store.getters.showAlert"
        v-bind:status="$store.getters.showAlert"
        v-bind:message="$store.getters.alertMessage"
        v-bind:color="$store.getters.alertColor"
      >
      </snak-bar>
  </div>
</template>
<script>
import axios from 'axios'
import {removeCookies} from '@/utils/cookies'
// import {FilterPermissions} from '@/utils/Permissions.js'
import SubPopup from '@/View/components/subPopup.vue'
import snakBar from '@/View/components/SnakBar.vue'
import Loader from '@/View/components/Loader.vue'
import LeftNavbar from "@/View/layout/components/LeftNavbar.vue";
import TopNavbar from "@/View/layout/components/TopNavbar.vue";
import router from '@/router/index.js'
export default {
  name: "App",
  components: {
    SubPopup,
    snakBar,
    Loader,
    LeftNavbar,
    TopNavbar,
  },
  data() {
    return {
      orgDetail: null,
      // temp: FilterPermissions(''),
      planObj: null,
      isDisableScreen: false,
      hideLayout: this.$store.getters.setLayout,
      pageContentHeight: window.innerHeight - 80,
      cCode: '',
    }
  },
  watch: {
  },
  beforeMount () {
    router.afterEach((to) => {
      console.log('to.name22', to.name)
      if (to.name === 'login' || to.name === 'DisAssociationHash' || to.name === 'InvoiceDetailHash' || to.name === 'DownloadHashInvoice' || to.name === 'PaymentDetailHash' || to.name === 'DownloadHashPayment' || to.name === 'QuoteDetailHash' || to.name === 'DownloadHashQuote'  || to.name === 'JobDetailHash' || to.name === 'DownloadHashJob' || to.name === 'registerAsOwner' || to.name === 'plan' || to.name === 'paymentSuccess' || to.name === 'EndUser' || to.name === 'EndUserJobDetail' || to.name === 'EndUserVisitDetail' || to.name === 'EndUserInvoiceDetail' || to.name === 'EndUserQuoteDetail' || to.name === 'EndUserPaymentDetail' || to.name === 'EndUserPaymentListing' || to.name === 'EndUserVisitListing' || to.name === 'EndUserQuoteListing' || to.name === 'EndUserInvoiceListing'  || to.name === 'jd' || to.name === 'rsd') {
        console.log('to.name', to.name)
          this.hideLayout= true
          this.isDisableScreen = false
      } else {
          this.hideLayout= false
      }
    })
  },
  mounted() {
    this.orgDetail = JSON.parse(localStorage.getItem('OrgQuery'))
    console.log('$store.getters.SubPopup', this.$store.getters.SubPopup)
    this.hideLayout= this.$store.getters.setLayout
    this.$root.$on("disableAllscreen", (data, response) => {
      console.log('data123213', data)
      console.log('response1313', response)
      this.isDisableScreen = data
      this.planObj = response
    });
    axios.get(`https://ipapi.co/json/`).then(data => {
      if (data.status === 200) {
        console.log('data', data)
        this.cCode = data.data.country
      }
    })
    // router.beforeEach(async (to) => {
    // if (to.name === 'login') {
    //     this.hideLayout= true
    // } else {
    //     this.hideLayout= false
    // }
    // })
    router.afterEach((to) => {
      console.log('to.name', to.name)
      if (to.name === 'login'|| to.name === 'DisAssociationHash' || to.name === 'InvoiceDetailHash' || to.name === 'DownloadHashInvoice' || to.name === 'PaymentDetailHash' || to.name === 'DownloadHashPayment'|| to.name === 'QuoteDetailHash' || to.name === 'DownloadHashQuote' || to.name === 'JobDetailHash' || to.name === 'DownloadHashJob' || to.name === 'registerAsOwner' || to.name === 'plan' || to.name === 'paymentSuccess' || to.name === 'EndUser' || to.name === 'EndUserJobListing' || to.name === 'plan' || to.name === 'Varify' || to.name === 'jd' || to.name === 'rsd') {
          this.hideLayout= true
      } else {
          this.hideLayout= false
      }
    })



  },
  methods: {
    backLogin () {
      location.reload()
      removeCookies('authToken')
      localStorage.clear()
    },
    openPlanDetail () {
      this.$router.push({name: 'plan', query: {currentPlanId: this.planObj.planId, currencyCode: this.cCode}})
      // let routeData1 = this.$router.resolve({name: 'plan'})
      // window.open(routeData1.href, '_blank')
    }
  }
};
</script>
<style scoped>
.letf_nav_box {
  margin-right: 71px;
}
.top_nav_box {
  height: 70px;
}
.topAlert {
  font-size: 14px;
}
@media screen and (max-width: 1040px) {
 .topAlert {
  font-size: 12px;
 }
}
@media screen and (max-width: 700px) {
 .topAlert {
  font-size: 10px;
 }
}
@media screen and (max-width: 450px) {
 .topAlert {
  font-size: 8px;
 }
}
</style>
