var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('div',{class:` rounded
      bg-white min-w-max
      border border-primary
      text-primary
      px-4 xl:px-4
      ${_vm.smallSize ? 'py-2' : 'py-2' }
      shadow-mds
    `,attrs:{"role":"alert"}},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"py-1"},[_c('svg',{staticClass:"fill-current h-6 w-6 text-primary mr-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"}})])]),_c('div',{staticClass:"w-full"},[_c('p',{staticClass:"xl:text-center heading-6"},[_vm._v(_vm._s(_vm.alertMessage))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }