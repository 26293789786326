var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center"},[_c('button',{staticClass:"shadow hover:shadow-xl global_btn",class:`
     ${_vm.btnBorder ? `border border-${_vm.borderColor} ` : ''}
     bg-${_vm.btnColor} hover:bg-opacity-75
     text-${_vm.textColor}
     ${_vm.btnSizeVal === 'chip' ? 'px-5 py-1 heading-6 rounded' : ''}
     ${_vm.btnSizeVal === 'large' ? 'py-3 px-5 cta-text' : ''}
     ${_vm.btnSizeVal === 'planChip' ? 'py-3 px-6 cta-text font-semibold' : ''}
     ${_vm.btnSizeVal === 'medium' ? 'py-2 px-5 cta-text' : ''}
     ${_vm.btnSizeVal === 'small' ? 'py-2 px-3 heading-7' : ''}
     ${_vm.isCurNormal ? 'cursor-default' : 'cursor-pointer'}
     ${_vm.disabledBtn ? 'opacity-20 cursor-auto ' : ''}
     ${_vm.isSqure ? '' : 'rounded-xl'}
     `,attrs:{"type":"button","disabled":_vm.disabledBtn},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.buttonHandler.apply(null, arguments)}}},[(_vm.iconClass !== undefined)?_c('span',{staticClass:"pr-1"},[_c('i',{class:`${_vm.iconClass}`})]):_vm._e(),_vm._v(" "+_vm._s(_vm.btnText)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }