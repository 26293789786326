import {getCookies} from '@/utils/cookies'
import MyJobApp from '@/api/MyJobApp.js'
const user = {
  state: {
    token: getCookies('authToken'),
    name: '',
    avatar: '',
    urlList: ['url'],
    normalDateFormat: '',
    longDateFormat: '',
    shortDateFormat: '',
    permission: [],
    projectPermission: {
      projectId: 0,
      permissionList: []
    },
    isAdmin: '',
    googleMapApiKey: 'AIzaSyDo4Cf7V2_lPZMfNXciostEss8xtfLgRRs',
    noOfDeviceRequest: 0,
    googleMapKey: '',
    timeSheetIconColor: null
  },
  mutations: {
    SET_TIMESHEET_ICON_COLOR: (state, color) => {
      state.timeSheetIconColor = color
    },
    SET_GOOGLE_MAP_KEY: (state, key) => {
      state.googleMapKey = key
    },
    DEVICE_REQUEST: (state, request) => {
      state.noOfDeviceRequest = request
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_URL_LIST: (state, urlList) => {
      state.urlList = urlList
    },
    SET_PERMISSIONS: (state, permission) => {
      state.permission = permission
      if (permission.googleApiKey !== '') {
        state.googleMapKey = permission.googleApiKey
      }
    },
    SET_PROJECT_PERMISSIONS: (state, permission) => {
      state.projectPermission = permission
    },
    SET_MENULIST: (state, menuList) => {
      state.menuList = menuList
    },
    SET_ADMIN: (state, isAdmin) => {
      state.isAdmin = isAdmin
    },
  },
  actions: {
    SetName ({commit}, Name) {
      commit('SET_NAME', Name)
    },
    SetToken ({commit}, token) {
      commit('SET_TOKEN', token)
    },
    SetAvatar ({commit}, avatar) {
      commit('SET_AVATAR', avatar)
    },
    SetUrlList ({commit}, urlList) {
      commit('SET_URL_LIST', urlList)
    },
    SetMenuList ({commit}, menuList) {
      commit('SET_MENULIST', menuList)
    },
    SetAdmin ({commit}, isadmin) {
      commit('SET_ADMIN', isadmin)
    },
    SetMapKey ({commit}, key) {
      commit('SET_GOOGLE_MAP_KEY', key)
    },
    SetTimeSheetIconColor ({commit}, key) {
      commit('SET_TIMESHEET_ICON_COLOR', key)
    },
    GetEmployeePermission ({commit, getters}, logOut) {
      console.log(getters)
      if (logOut) {
        commit('SET_PERMISSIONS', [])
      } else {
        return new Promise((resolve, reject) => {
          MyJobApp.GetLogedInEmployeePermissions(
            response => {
              console.log('permission response', response.Data)
              commit('SET_PERMISSIONS', response.Data)
              resolve(response.Data)
            },
            (error) => {
              reject(error)
            }
          )
        })
      }
    },
  }
}
export default user
