<template>
  <div id="inspire">
    <div class="flex justify-center z-50">
      <div class="">
        <LeftNavbar />
      </div>
      <div class="w-full p-2 pt-4">
        <div class="card rounded-lg h-screen overflow-y-auto overflow-x-hidden bg-white" style="max-height: 96vh;">
          <router-view />
        </div>
      </div>
    </div>
    <loader
      v-if="$store.getters.showLoader"
      v-bind:data="$store.getters.loaderMessage"
    ></loader>
    <snakBar
        v-if="$store.getters.showAlert"
        v-bind:status="$store.getters.showAlert"
        v-bind:message="$store.getters.alertMessage"
        v-bind:color="$store.getters.alertColor"
      >
      </snakBar>
  </div>
</template>
<script>
// var moment = require('moment')
import MyJobApp from '@/api/MyJobApp.js'
import LeftNavbar from '@/View/endUserNewFlow/components/leftNavBar.vue'
import Loader from '@/View/components/Loader.vue'
import snakBar from '@/View/components/SnakBar.vue'
export default {
  components: {
    Loader,
    snakBar,
    LeftNavbar,
  },
  data () {
    return {
      userEmail: '',
      userPass: '',
      emailErr: '',
      passErr: '',
      emailValid: false
    }
  },
  created () {},
  mounted () {
    console.log('THiSSSSSSSSSSS', this.$route.name)
    this.getTokenVarification(this.$route.params.customerToken)
    this.$root.$on("tokenVarificationApi", (response) => {
      this.getTokenVarification(response)
    });
  },
  methods: {
    getTokenVarification (token) {
      MyJobApp.TokenVerified(
        token,
        response => {
          console.log('error response', response)
          this.$root.$emit('tokenResponse', response.isTokenExpired, response.isTokenInvalid)
          // let startDate = moment(new Date()).format('YYYY-MM-DD')
          // let expDate = moment(new Date(response.Data.ed)).format('YYYY-MM-DD')
          // console.log('startDate', startDate)
          // if (startDate > expDate) {
          //   this.$root.$emit('tokenResponse', true, '')
          // } else {
          //   this.$root.$emit('tokenResponse', false, '')
          // }
        },
        (error) => {
          console.log('error', error)
          // this.$root.$emit('tokenResponse', error.isTokenExpired, error.isTokenInvalid)
        }
      )
    },
    checkApprover () {
      this.$store.dispatch('SetAlert', {showAlert: true, message: 'Your Approval is pending', color: 'secondory'})
    },
  },
  beforeDestroy() {
    this.$root.$off('tokenVarificationApi')
  },
  computed: {
  }
}
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Saira+Stencil+One&display=swap");
.card_design {
  max-width: 600px;
}

</style>
