import Layout from '@/View/endUserNewFlow/layout.vue'
export const endUser = { // dashboard object start
    path: '/endUser',
    component: Layout,
    hidden: false,
    name: 'EndUser',
    redirect: '/customer/:customerToken',
    meta: {
      title: 'EndUser',
      selected: true,
      permissionList: [],
      loadPage: 'EndUser',
    },
    children: [
      {
        path: '/customer/:customerToken/job',
        name: 'EndUserJobListing',
        component: () => import('@/View/endUserNewFlow/job/listing.vue'),
        hidden: true,
        meta: {
          title: 'EndUser: Job',
          selected: false,
          permissionList: [],
          loadPage: 'EndUserJobListing',
        }
      },
      {
        path: '/customer/:customerToken/job/:jobToken',
        name: 'EndUserJobDetail',
        component: () => import('@/View/endUserNewFlow/job/detail.vue'),
        hidden: true,
        meta: {
          title: 'EndUser: Job',
          selected: false,
          permissionList: [],
          loadPage: 'EndUserJobListing',
        }
      },
      {
        path: '/customer/:customerToken/invoice',
        name: 'EndUserInvoiceListing',
        component: () => import('@/View/endUserNewFlow/invoice/listing.vue'),
        hidden: true,
        meta: {
          title: 'EndUser: Invoice',
          selected: false,
          permissionList: [],
          loadPage: 'EndUserInvoiceListing',
        }
      },
      {
        path: '/customer/:customerToken/invoice/:invoiceToken',
        name: 'EndUserInvoiceDetail',
        component: () => import('@/View/endUserNewFlow/invoice/detail.vue'),
        hidden: true,
        meta: {
          title: 'EndUser: Invoice',
          selected: false,
          permissionList: [],
          loadPage: 'EndUserInvoiceListing',
        }
      },
      {
        path: '/customer/:customerToken/quotation',
        name: 'EndUserQuoteListing',
        component: () => import('@/View/endUserNewFlow/quote/listing.vue'),
        hidden: true,
        meta: {
          title: 'EndUser: Quotation',
          selected: false,
          permissionList: [],
          loadPage: 'EndUserQuoteListing',
        }
      },
      {
        path: '/customer/:customerToken/quotation/:quoteToken',
        name: 'EndUserQuoteDetail',
        component: () => import('@/View/endUserNewFlow/quote/detail.vue'),
        hidden: true,
        meta: {
          title: 'EndUser: Quotation',
          selected: false,
          permissionList: [],
          loadPage: 'EndUserQuoteListing',
        }
      },
      {
        path: '/customer/:customerToken/visit',
        name: 'EndUserVisitListing',
        component: () => import('@/View/endUserNewFlow/visit/listing.vue'),
        hidden: true,
        meta: {
          title: 'EndUser: Visit',
          selected: false,
          permissionList: [],
          loadPage: 'EndUserVisitListing',
        }
      },
      {
        path: '/customer/:customerToken/visit/:visitToken',
        name: 'EndUserVisitDetail',
        component: () => import('@/View/endUserNewFlow/visit/detail.vue'),
        hidden: true,
        meta: {
          title: 'EndUser: Visit',
          selected: false,
          permissionList: [],
          loadPage: 'EndUserVisitListing',
        }
      },
      {
        path: '/customer/:customerToken/payment',
        name: 'EndUserPaymentListing',
        component: () => import('@/View/endUserNewFlow/payment/listing.vue'),
        hidden: true,
        meta: {
          title: 'EndUser: Payment',
          selected: false,
          permissionList: [],
          loadPage: 'EndUserPaymentListing',
        }
      },
      {
        path: '/customer/:customerToken/payment/:paymentToken',
        name: 'EndUserPaymentDetail',
        component: () => import('@/View/endUserNewFlow/payment/detail.vue'),
        hidden: true,
        meta: {
          title: 'EndUser: Payment',
          selected: false,
          permissionList: [],
          loadPage: 'EndUserPaymentListing',
        }
      },
    ]
}