import router from '../src/router/index'
import store from './store'
import MyJobApp from '@/api/MyJobApp.js'
import {setCookiesWithExpire} from '@/utils/cookies.js'

router.beforeEach(async (to, from, next) => {
  console.log('to.query', to.query)
  if (to.query.uid !== undefined && to.query.uid !== null && to.query.uid !== 0) {
    localStorage.clear('jobUserInfo')
    MyJobApp.DirectLogin(
      to.query.uid,
      (response) => {
        console.log('response CheckIpExpireOrNot', response.Data)
        let latLong = {
          latitude: response.Data.organizationAddress.latitude,
          longitude: response.Data.organizationAddress.longitude
        }
        localStorage.setItem('orgInfo', JSON.stringify(latLong))
        localStorage.setItem('jobUserInfo', JSON.stringify(response.Data.userInfo))
        localStorage.setItem('OrgQuery', JSON.stringify(to.query))
        setCookiesWithExpire('jobAuthToken', response.Data.userInfo.authToken, 30)
        next({
          name: 'dashboard1'
        })
        store.dispatch('SetLoader', {status: false, message: ''})
        store.dispatch('SetShowIpExpiredPopup', {showIpExpirePopup: false})
      },
      error => {
        if (error.Data !== undefined || error.Data !== null) {
          if (error.Data === true) {
            console.log('CheckIpExpireOrNot', error)
            // store.dispatch('SetShowIpExpiredPopup', {showIpExpirePopup: true})
            localStorage.clear()
            next({
              name: 'login'
            })
          }
        }
        localStorage.clear()
        next({
          name: 'login'
        })
      }
    )
  } else {
    let companyData = localStorage.getItem('jobUserInfo')
    console.log('to.nameto.nameto.name', to.name)
    if (to.name === 'login') {
      store.dispatch('SetLayoutVal', true)
    } else {
      store.dispatch('SetLayoutVal', false)
    }
  
    if (((to.name === 'login' || to.name === 'registerAsOwner') && companyData === null) ) {
      next()
    } else {
      if (to.name === 'Varify' || to.name === 'EndUserJobListing' || to.name === 'EndUserJobDetail' || to.name === 'EndUserInvoiceDetail' || to.name === 'EndUserInvoiceListing' || to.name === 'EndUserQuoteListing' || to.name === 'EndUserQuoteDetail' || to.name === 'EndUserVisitListing' || to.name === 'EndUserVisitDetail' || to.name === 'EndUserPaymentListing' || to.name === 'EndUserPaymentDetail' || to.name === 'DownloadHashInvoice' || to.name === 'DownloadHashPayment' || to.name === 'DownloadHashQuote' || to.name === 'DownloadHashJob' || to.name === 'plan' || to.name === 'jd' || to.name === 'rsd') {
        next()
      } else {
        if (companyData === null) {
          next({
            name: 'login'
          })
        } else {
          if (to.name !== 'login' && to.path !== 'registerAsOwner') {
            next()
          } else {
            next({
              name: 'dashboard1'
            })
          }
        }
      }
    }
  }
})