<template>
  <div class="card bg-white rounded-xl p-2">
    <div>
      <div style="overflow-y: auto; white-space: nowrap;border-collapse: collapse; " class="relative" v-if="isFirstLoad">
        <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center" v-if="!mobileView">
          <div class="lead_name_col uppercase ">Company Name</div>
          <div class="lead_name_col2 uppercase ">Hot Note</div>
        </div>
        <div class=" flex table_containder min-w-min" style="flex-flow:wrap;" v-if="!mobileView">
          <div class="flex p-2 min-w-min rounded w-full my-1 border-b border-gray1 hover:bg-gray-100 cursor-pointer" @click="showJobDetail(data.customerId)" v-for="(data, index) in leadList" :key="index">
            <div class="lead_name_col text-gray4 heading-6">
              <span v-if="data.companyName !== ''" v-html="matchFind(data.companyName)"></span>
              <span v-else>-</span>
            </div>
            <div class="lead_name_col2 text-gray4 heading-6" >
              <span v-if="data.hotNotes !== ''" class="overFlowParaA" v-html="matchFind(data.hotNotes)"></span>
              <span v-else>-</span>
            </div>
            <div class="border border-gray2 p-2 rounded card card_col">
              <div v-for="(dataA, indexB) in data.contactList.contacts" :key="indexB" class="flex hover:bg-gray-100 group cursor-pointer " :class="data.contactList.contacts > 1 ? 'py-0.5' : ''">
                  <div>
                    <p class=" text-text2"><span v-html="matchFind(dataA.fn)"></span> <span v-html="matchFind(dataA.ln)"></span></p>
                    <p class=" flex flex-wrap heading-6  text-text1">
                      <span class="pl-1">
                        <span v-if="dataA.pn !== ''" class="text-gray3">P:</span> {{dataA.pn| phone}} 
                        <span class="text-gray3" v-if="dataA.on !== ''">,</span>
                      </span>
                      <span v-if="dataA.on !== ''" class="pl-1">
                          <span class="text-gray3"> O:</span> {{dataA.on| phone}}
                      </span>
                      <span class="text-gray3" v-if="dataA.on === '' && dataA.pn === ''" >-</span>
                    </p>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="mobileView">
            <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="isFirstLoad">
              <div v-for="(data, index) in leadList" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="showJobDetail(data.customerId)">
                <div class="">
                  <div class="flex justify-between items-center">
                    <p class="text-gray4 heading-6" v-html="matchFind(data.companyName)"></p>
                  </div>
                  <p v-if="data.hotNotes !== ''" class="text-gray4 heading-6 overFlowParaA" v-html="matchFind(data.hotNotes)"></p>
                </div>
                <div class="border border-gray2 p-2 rounded card card_col">
                <div v-for="(dataA, indexB) in data.contactList.contacts" :key="indexB" class="flex hover:bg-gray-100 group cursor-pointer " :class="data.contactList.contacts > 1 ? 'py-0.5' : ''">
                    <div>
                      <p class=" text-text2 heading-6"><span v-html="matchFind(dataA.fn)"></span> <span v-html="matchFind(dataA.ln)"></span></p>
                      <p class=" flex flex-wrap heading-6  text-text2">
                        <span class="pl-1">
                          <span v-if="dataA.pn !== ''" class="text-gray3">P:</span> {{dataA.pn| phone}} 
                          <span class="text-gray3" v-if="dataA.on !== ''">,</span>
                        </span>
                        <span v-if="dataA.on !== ''" class="pl-1">
                            <span class="text-gray3"> O:</span> {{dataA.on| phone}}
                        </span>
                        <span class="text-gray3" v-if="dataA.on === '' && dataA.pn === ''" >-</span>
                      </p>
                    </div>
                </div>
              </div>
              </div>
            </div>
        </div>
      </div>
      <div v-if="leadList.length === 0 && isFirstLoad">
        <NoRecords :alertMessage="` No Lead found`" />
      </div>
    </div>
  </div>
</template>
<script>
import NoRecords from '@/View/components/noRecords.vue'
var moment = require('moment')
import MyJobApp from '@/api/MyJobApp.js'
export default {
  components: {
    NoRecords
  },
  data() {
    return {
      leadList: [],
      searchValue: '',
      mobileView: false,
      isFirstLoad: false,
    };
  },
  watch: {
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.$root.$on("leadSearchingCall", (response) => {
      this.searchValue = response
      this.leadListApi (response)
    });
  },
  methods: {
    leadListApi (data) {
      this.isFirstLoad = false
      MyJobApp.GlobalLeadSearch(
        0,
        500,
        data,
        response => {
          let leadList = response.Data.tableRow !== null ? response.Data.tableRow : []
          for (let index = 0; index < leadList.length; index++) {
            leadList[index].contactList = JSON.parse(leadList[index].contactList)
          }
          this.leadList = leadList
          this.$root.$emit('leadSearchingCount', response.Data.count)
          this.isFirstLoad = true
        },
        (error) => {
          this.isFirstLoad = true
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    dateFilter (dateTime, value) {
      let checkDate = new Date(dateTime)
      let timeValue = moment(checkDate, 'ddd DD-MMM-YYYY, hh:mm a').format('hh:mm a')
      let todayDate = new Date().setHours(0, 0, 0, 0)
      var fullDate = moment(dateTime).format('MMM DD, ')
      let dayName = moment(dateTime).format('ddd')
      dateTime = new Date(dateTime).toUTCString()
      let requestDt = dateTime
      requestDt = new Date(requestDt).setHours(0, 0, 0, 0)
      if (value === 'scheduleDate') {
        return fullDate + ' ' + dayName
      } else if (value === 'scheduleTime') {
        return timeValue
      } else {
        if (moment(todayDate).diff(requestDt, 'days') === 0) {
          return 'Today ' + timeValue
        }
        let difDay = moment(todayDate).diff(requestDt, 'days')
        if (difDay > 1) {
          return fullDate + ' ' + timeValue
        }
        if (difDay === 1) {
          return 'Yesterday ' + timeValue
        }
      }
    },
    resizeWindowHandler() {
      if (window.innerWidth < 1194) {
        this.mobileView = true
      } else {
        this.mobileView = false
      }
    },
    showJobDetail (id) {
      this.$router.push({name: 'LeadDetail', params: {leadId: id}})
      this.$root.$emit('closeGlobalSearchDialog')
    },
    matchFind (str) {
      if (this.searchValue === null || this.searchValue === undefined || this.searchValue === ' ') {
        return
      }
      let searchStrArray = this.searchValue.split(' ')
      let replceString = str.trim()
      for (let i = 0; i < searchStrArray.length; i++) {
        if (searchStrArray[i].trim() !== '' && searchStrArray[i].trim() !== 'em') {
          replceString = replceString.replace(new RegExp(searchStrArray[i], 'gi'), match => {
            return '<em style="background-color:#78c8f294">' + match + '</em>'
          })
        }
      }
      return replceString
    }
  },
  beforeDestroy() {
    this.$root.$off('leadSearchingCall')
  },
};
</script>
<style scoped>
.lead_name_col {
  min-width: 220px !important;
  width: 220px !important;
  max-width: 220px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.lead_name_col2 {
  min-width: 360px !important;
  width: 360px !important;
  max-width: 360px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
  
  