<template>
  <div>
    <div id="myTopnav" class="topNav bg-screen_bg">
      <div v-if="userAndCompDetail !== null" class="w-full ">
        <div class="flex justify-between items-center " v-if="!mobileView">
          
          <div class="flex card bg-white rounded-xl p-2 w-full" :class="`${!planTodayExpire && !planSomeDaysError && !showStorageErr ? 'items-center h-14' : ''} ${showStorageErr && (planTodayExpire || planSomeDaysError) ? 'h-18' : !showStorageErr && (planTodayExpire || planSomeDaysError) ? 'h-14' : 'h-14'}`">
            <div class="text-primary text-xl cursor-pointer pl-2 overFlowParaA " :class="!planTodayExpire && !planSomeDaysError ? 'font-bold ' : 'heading-4 font-semibold '" @click="redirectDashboard()">{{userAndCompDetail.firstName}} {{userAndCompDetail.lastName}}</div>
            <!-- <div class="absolute -bottom-1 pl-2 heading-6 text-error" v-if="planOverError && !planTodayExpire && !planSomeDaysError" >*Your {{this.planDetaiObj.isFree ? 'Free/trial' : this.planDetaiObj.currentPlanName}} Has ended.please <router-link :to="{ name: 'plan'} " target="_blank" class="cursor-pointer text-blue-400 hover:underline">click here</router-link> to upgrade your plan to continue</div> -->
            <div class="absolute -bottom-1 pl-2 heading-6 text-error" v-if="!planOverError && planTodayExpire && !planSomeDaysError" >*Your {{this.planDetaiObj.isFree ? 'Trial is about ' : this.planDetaiObj.currentPlanName + ' plan Has'}} expire.please <router-link :to="{ name: 'plan' , query: {currentPlanId: this.planDetaiObj.planId, currencyCode: this.cCode}} " target="_blank" class="cursor-pointer text-blue-400 hover:underline">click here</router-link> to upgrade your plan to continue</div>
            <div class="absolute -bottom-1 pl-2 heading-6 text-error" v-if="!planOverError && !planTodayExpire && planSomeDaysError" >*Your {{this.planDetaiObj.isFree ? 'free trial ' : this.planDetaiObj.currentPlanName + ' plan '}} expires in {{this.diffDays}} days, please <router-link :to="{ name: 'plan' , query: {currentPlanId: this.planDetaiObj.planId, currencyCode: this.cCode}} " target="_blank" class="cursor-pointer text-blue-400 hover:underline">click here</router-link> to upgrade your plan to continue</div>
            <div :class="`${showStorageErr && (planTodayExpire || planSomeDaysError) ? '-bottom-5' : showStorageErr && (!planTodayExpire && !planSomeDaysError) ? '-bottom-1' : ''}`" class="absolute -bottom-1 pl-2 heading-6 text-error" v-if="showStorageErr" >*You have utilized 90% of your {{this.planDetaiObj.currentPlanStorage}} GB space of your current plan, please upgrade your plan or manage your space by logging into mobile app to continue</div>
          </div>
          <div class="flex gap-4 ml-4 ">
            <div class="d_icon_child">
              <div class="flex items-center justify-center text-text2 font-bold hover:text-primary cursor-pointer heading-4 border transition delay-75 border-white hover:border-primary card bg-white rounded-xl p-2 w-16 h-14" @click="openSupport()">
               <i class="fa-solid fa-headset"></i>
              </div>
              <span class="tooltiptext heading-4 bg-transparent whitespace-nowrap rounded">Support</span>
            </div>
            <div class="d_icon_child">
              <div class="flex items-center justify-center text-text2 font-bold hover:text-primary cursor-pointer heading-4 border transition delay-75 border-white hover:border-primary card bg-white rounded-xl p-2 w-16 h-14" @click="openFeedback()">
                <i class="fa-solid fa-file-pen"></i>
               </div>
              <span class="tooltiptext heading-4 bg-transparent whitespace-nowrap rounded">Send Feedback</span>
            </div>
            <div class="d_icon_child">
              <div class="flex items-center justify-center text-text2 font-bold hover:text-primary cursor-pointer heading-4 border transition delay-75 border-white hover:border-primary card bg-white rounded-xl p-2 w-16 h-14" @click="openGlobalPopup()">
                <i class="fa-solid fa-magnifying-glass"></i>
               </div>
              <span class="tooltiptext heading-4 bg-transparent whitespace-nowrap rounded">Global Search</span>
            </div>
            <div class="d_icon_child">
              <div class="flex items-center justify-center text-text2 font-bold hover:text-primary cursor-pointer heading-4 border transition delay-75 border-white hover:border-primary card bg-white rounded-xl p-2 w-16 h-14" @click="redirectToPlan()">
                <i class="fa-solid fa-hand-holding-dollar"></i>
               </div>
              <span class="tooltiptext heading-4 bg-transparent whitespace-nowrap rounded">Subscription</span>
            </div>
            <div class="d_icon_child">
              <div class="flex items-center justify-center text-text2 font-bold hover:text-primary cursor-pointer heading-4 border transition delay-75 border-white hover:border-primary card bg-white rounded-xl p-2 w-16 h-14 relative" @click="redirectDetail()">
                <i class="fa-solid fa-user"></i>
                <span v-if="!userAndCompDetail.isEmailVerify" style="border-radius: 100%; padding-left: 6px; padding-right: 6px;" class="text-error border border-error text absolute bottom-8 right-1">
                  <i  class="fa-solid fa-exclamation"></i>
                </span>
               </div>
              <span class="tooltiptext heading-4 bg-transparent whitespace-nowrap rounded">My Profile</span>
            </div>
            <div class="d_icon_child">
              <div class="flex items-center justify-center text-text2 font-bold hover:text-primary cursor-pointer heading-4 border transition delay-75 border-white hover:border-primary card bg-white rounded-xl p-2 w-16 h-14" @click="goToOrgDetail()">
                <i class="fa-solid fa-house-chimney-user"></i>
               </div>
              <span class="tooltiptext heading-4 bg-transparent whitespace-nowrap rounded">Organization</span>
            </div>
            <div class="d_icon_child">
              <div class="flex items-center justify-center text-text2 font-bold hover:text-primary cursor-pointer heading-3 border transition delay-75 border-white hover:border-primary card bg-white rounded-xl p-2 w-16 h-14" @click="goLoginPage()">
                <i class="fa-solid fa-right-from-bracket"></i>
               </div>
              <span class="tooltiptext heading-4 bg-transparent whitespace-nowrap rounded">Log Out</span>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center overflow-auto relative" v-if="mobileView">
          <div class="flex items-center card bg-white rounded-xl p-2 h-12 sticky left-0" :class="extraSmall ? '' : 'w-full'">
            <div @click="smallViewOpenDrawer">
              <div class="heading-3 text-gray3 pr-1">
                <i class="fa-solid fa-bars"></i>
              </div>
            </div>
            <div v-if="!extraSmall" class="text-primary font-semibold text-md cursor-pointer pl-2 " @click="redirectDashboard()">{{userAndCompDetail.firstName}} {{userAndCompDetail.lastName}}</div>
          </div>
          <div class="flex gap-2 ml-2 ">
            <div class="flex items-center justify-center text-text2 font-bold hover:text-primary cursor-pointer heading-5 border transition delay-75 border-white hover:border-primary card bg-white rounded-xl p-1 w-12 h-12" @click="openSupport()">
             <i class="fa-solid fa-headset"></i>
            </div>
            <div class="flex items-center justify-center text-text2 font-bold hover:text-primary cursor-pointer heading-5 border transition delay-75 border-white hover:border-primary card bg-white rounded-xl p-1 w-12 h-12" @click="openFeedback()">
             <i class="fa-solid fa-file-pen"></i>
            </div>
            <div class="flex items-center justify-center text-text2 font-bold hover:text-primary cursor-pointer heading-5 border transition delay-75 border-white hover:border-primary card bg-white rounded-xl p-1 w-12 h-12" @click="openGlobalPopup()">
             <i class="fa-solid fa-magnifying-glass"></i>
            </div>
            <div class="flex items-center justify-center text-text2 font-bold hover:text-primary cursor-pointer heading-5 border transition delay-75 border-white hover:border-primary card bg-white rounded-xl p-1 w-12 h-12" @click="redirectToPlan()">
              <i class="fa-solid fa-hand-holding-dollar"></i>
            </div>
            <div class="flex items-center justify-center text-text2 font-bold hover:text-primary cursor-pointer heading-5 border transition delay-75 border-white hover:border-primary card bg-white rounded-xl p-1 w-12 h-12" @click="redirectDetail()">
             <i class="fa-solid fa-user"></i>
            </div>
            <div class="flex items-center justify-center text-text2 font-bold hover:text-primary cursor-pointer heading-5 border transition delay-75 border-white hover:border-primary card bg-white rounded-xl p-1 w-12 h-12" @click="goToOrgDetail()">
             <i class="fa-solid fa-house-chimney-user"></i>
            </div>
            <div class="flex items-center justify-center text-text2 font-bold hover:text-primary cursor-pointer heading-4 border transition delay-75 border-white hover:border-primary card bg-white rounded-xl p-1 w-12 h-12" @click="goLoginPage()">
             <i class="fa-solid fa-right-from-bracket"></i>
            </div>
          </div>
        </div>
        <div v-if="mobileView">
          <div class="pl-2 heading-6 text-error" v-if="!planOverError && planTodayExpire && !planSomeDaysError" >*Your {{this.planDetaiObj.isFree ? 'Trial is about ' : this.planDetaiObj.currentPlanName + ' plan Has'}} expire.please <router-link :to="{ name: 'plan' , query: {currentPlanId: this.planDetaiObj.planId, currencyCode: this.cCode}} " target="_blank" class="cursor-pointer text-blue-400 hover:underline">click here</router-link> to upgrade your plan to continue</div>
          <div class="pl-2 heading-6 text-error" v-if="!planOverError && !planTodayExpire && planSomeDaysError" >*Your {{this.planDetaiObj.isFree ? 'free trial ' : this.planDetaiObj.currentPlanName + ' plan '}} expires in {{this.diffDays}} days, please <router-link :to="{ name: 'plan' , query: {currentPlanId: this.planDetaiObj.planId, currencyCode: this.cCode}} " target="_blank" class="cursor-pointer text-blue-400 hover:underline">click here</router-link> to upgrade your plan to continue</div>
        </div>
      </div>
      <!-- <div class="flex card bg-white rounded-xl p-2 w-full h-14" :class="!planTodayExpire && !planSomeDaysError ? 'items-center ' : ' '">
        <div class="text-primary text-xl cursor-pointer pl-2 overFlowParaA " :class="!planTodayExpire && !planSomeDaysError ? 'font-bold ' : 'heading-4 font-semibold '" @click="redirectDashboard()">{{userAndCompDetail.firstName}} {{userAndCompDetail.lastName}}</div>
        <div class="absolute -bottom-1 pl-2 heading-6 text-error" v-if="!planOverError && planTodayExpire && !planSomeDaysError" >*Your {{this.planDetaiObj.isFree ? 'Trial is about ' : this.planDetaiObj.currentPlanName + ' plan Has'}} expire.please <router-link :to="{ name: 'plan'} " target="_blank" class="cursor-pointer text-blue-400 hover:underline">click here</router-link> to upgrade your plan to continue</div>
        <div class="absolute -bottom-1 pl-2 heading-6 text-error" v-if="!planOverError && !planTodayExpire && planSomeDaysError" >*Your {{this.planDetaiObj.isFree ? 'free trial ' : this.planDetaiObj.currentPlanName + ' plan '}} expires in {{this.diffDays}} days, please <router-link :to="{ name: 'plan'} " target="_blank" class="cursor-pointer text-blue-400 hover:underline">click here</router-link> to upgrade your plan to continue</div>
      </div> -->
    </div>
    <!-- <div id="myTopnav" class="topNav bg-screen_bg">
         <div v-if="userAndCompDetail !== null" class="w-full">
           <div class="flex justify-between items-center">
             <div class="flex items-center">
               <div @click="smallViewOpenDrawer" v-if="mobileView">
                 <div class="heading-1 text-gray3 pr-1">
                   <i class="fa-solid fa-box"></i>
                 </div>
               </div>
               <div class="text-primary font-bold text-2xl">{{userAndCompDetail.organizationName}}</div>
             </div>
             <div class="flex gap-4 items-center">
               <div @click="openGlobalPopup()" class="text-text2 font-bold hover:text-primary cursor-pointer">
                 <i class="fa-solid fa-magnifying-glass"></i>
               </div>
               <div class="text-text2 font-bold hover:text-primary cursor-pointer">
                 Clock-In-Now...
               </div>
               <div class="text-text2 font-bold hover:text-primary cursor-pointer" @click="goToOrgDetail()">
                 <i class="fa-solid fa-house-chimney-user"></i>
               </div>
               <div class="text-text2 font-bold hover:text-primary cursor-pointer">
                 <i class="fa-solid fa-bell"></i>
               </div>
               <div @click="goLoginPage()" class="text-text2 font-bold hover:text-primary cursor-pointer">
                 <i class="fa-solid fa-user-alt-slash"></i>
               </div>
             </div>
           </div>
         </div>
        </div> -->
        <div v-if="isGlobalSearch">
          <GlobalMainSearch />
        </div>
  </div>
</template>

<script>
import {removeCookies} from '@/utils/cookies'
import GlobalMainSearch from '@/View/components/globalMainSearch.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
  components: {
    GlobalMainSearch
  },
  name: "TopNavbar",
  props: ['countryCode'],
  data() {
    return {
      showStorageErr: false,
      planSomeDaysError: false,
      planTodayExpire: false,
      planOverError: false,
      diffDays: 0,
      showExpireErr: false,
      showExpiredErr: false,
      planDetaiObj: null,
      userAndCompDetail: null,
      drawerIsOpen: false,
      mobileView: false,
      isGlobalSearch: false,
      extraSmall: false,
      cCode: '',
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.cCode = this.countryCode
    this.$root.$on("closeGlobalSearchDialog", () => {
      this.isGlobalSearch = false
    });
    this.$root.$on("manageProfileIcon", () => {
      this.userAndCompDetail = JSON.parse(localStorage.getItem('jobUserInfo'))
    });
    document.body.style.overflow = 'auto'
    document.body.style = 'overflow: visible;'
    this.searchWidth = window.innerWidth - 400
    this.resizeWindowHandler()
    if (localStorage.getItem('jobUserInfo') !== null) {
      this.userAndCompDetail = JSON.parse(localStorage.getItem('jobUserInfo'))
      // console.log('organizationDetailId', this.userAndCompDetail);
    }
    this.currentPlanDetail()
  },
  watch: {
    countryCode: {
      handler () {
        this.cCode = this.countryCode
      }
    },
    deep: true
  },
  methods: {
    openFeedback () {
      this.$router.push({name: 'Feedback'}).catch(()=>{});
    },
    openSupport () {
      this.$router.push({name: 'Support'}).catch(()=>{});
    },
    openGlobalPopup () {
      this.isGlobalSearch = true
    },
    redirectDashboard () {
      this.$router.push({name: 'dashboard1'}).catch(()=>{});
    },
    redirectDetail () {
      this.$router.push({ name: 'SettingEmployeesDetail', params: { empId: parseInt(this.userAndCompDetail.userDetailId) }, query: {showDelele: true}}).catch(()=>{});
    },
    goToOrgDetail () {
      this.$router.push({name: 'OrgDetail'}).catch(()=>{});
    },
    redirectToPlan () {
      this.$router.push({name: 'SubscriptionDetail'}).catch(()=>{});
    },
    goLoginPage () {
      removeCookies('jobAuthToken')
      localStorage.clear()
      this.$router.push({name: 'login'}).catch(()=>{});
      location.reload()
    },
    smallViewOpenDrawer() {
      if (this.mobileView) {
        setTimeout(() => {
          document.getElementById("sv_smySidenav").style.minWidth = "260px";
          document.getElementById("overlay").style.display = "block";
        }, 10);
      }
    },
    currentPlanDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.GetCurrentPlanDetail(
        response => {
          this.planDetaiObj = response.Data !== null ? response.Data : null
          const requestDt = new Date(this.planDetaiObj.currentPlanEndOn).setHours(0, 0, 0)
          const todayDate = new Date().setHours(0, 0, 0)
          // console.log('requestDt--->()', requestDt, this.planDetaiObj.currentPlanEndOn)
          // console.log('todayDate--->()', todayDate, new Date())
          const diffTime = Math.abs(todayDate - requestDt);
          this.diffDays = Math.ceil(parseFloat((diffTime / (1000 * 3600 * 24)).toFixed(2)));
          // console.log('diffDays', this.diffDays)
          if (this.diffDays === 0) {
            this.planOverError = false
            this.planTodayExpire = true
            this.planSomeDaysError = false
            this.$root.$emit('disableAllscreen', false, this.planDetaiObj)
          } else {
            if (requestDt < todayDate) {
              this.planOverError = true
              this.planTodayExpire = false
              this.planSomeDaysError = false
              this.$root.$emit('disableAllscreen', true, this.planDetaiObj)
            } else if (this.diffDays < 7) {
                this.planOverError = false
                this.planTodayExpire = false
                this.planSomeDaysError = true
                this.$root.$emit('disableAllscreen', false, this.planDetaiObj)
              // this.showExpireErr = 
              }
          }
          let totalStorage = parseInt(this.planDetaiObj.currentPlanStorage)*1024
          let fileSizeMB = this.planDetaiObj.totalUsedStorage / (1024 ** 2)
          let totalUsedStorage = fileSizeMB.toFixed(2)
          const totalUsedPers = ((100 * totalUsedStorage) / totalStorage)
          if (totalUsedPers >= 90) {
            this.showStorageErr = true
          } else {
            this.showStorageErr = false
          }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    resizeWindowHandler() {
      if (window.innerWidth < 1024) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
      if (window.innerWidth < 438) {
        this.extraSmall = true;
      } else {
        this.extraSmall = false;
      }
      this.searchWidth = window.innerWidth - 400
    }
  },
  beforeDestroy() {
    this.$root.$off('closeGlobalSearchDialog')
  },
};
</script>
<style scoped>
.topAlert {
  z-index: 2;
  width: 100%;
  font-size: 14px;
}
@media screen and (max-width: 1040px) {
 .topAlert {
  font-size: 12px;
 }
}
@media screen and (max-width: 700px) {
 .topAlert {
  font-size: 10px;
 }
}
@media screen and (max-width: 450px) {
 .topAlert {
  font-size: 8px;
 }
}

.d_icon_child {
  position: relative;
}

.tooltiptext {
  visibility: hidden;
  width: 150px;
  color: #fff;
  text-align: center;
  padding: 0px 0px;
  background-color: #0000009c;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: -30px;
  left: -40px;
}


.d_icon_child:hover .tooltiptext {
  visibility: visible;
}
</style>