import Vue from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import './assets/globals.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import './permission.js'
import 'quill-mention/dist/quill.mention.min.css'
import router from './router'
import store from './store'
import './filter/filter.js'
import vClickOutside from 'v-click-outside'
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.config.productionTip = false
import VueRx from "vue-rx"; Vue.use(VueRx);
import VuejsClipper from 'vuejs-clipper/dist/vuejs-clipper.umd'
import VueSignature from "vue-signature-pad";
import VueMask from 'v-mask'
Vue.use(VuejsClipper)
Vue.use(VueMask)
Vue.use(VueSignature)
Vue.use(vClickOutside)
Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
  load: {
    key: store.getters.googleMapApiKey,
    libraries: 'places,drawing' // necessary for places input
  }
})
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')