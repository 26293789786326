<template>
  <div class="card bg-white rounded-xl p-2">
    <div>
      <div style="overflow-y: auto; white-space: nowrap;border-collapse: collapse; " class="relative" v-if="isFirstLoad">
        <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center" v-if="!mobileView">
          <div class="table_col1 uppercase ">First Name</div>
          <div class="table_col1 uppercase ">Last Name</div>
          <div class="card_col uppercase ">Note</div>
          <div class="table_col2  uppercase">Shift Time</div>
        </div>
        <div class=" flex table_containder min-w-min" style="flex-flow:wrap;" v-if="!mobileView">
          <div class="flex p-2 min-w-min rounded w-full my-1 border-b border-gray1 hover:bg-gray-100 cursor-pointer" v-for="(data, index) in shiftList" :key="index" @click="redirectTo(data)">
            <div class="table_col1  text-gray4 heading-6">
              <span v-if="data.firstName !== ''" v-html="matchFind(data.firstName)"></span>
              <span v-else>-</span>
            </div>
            <div class="table_col1  text-gray4 heading-6">
              <span v-if="data.lastName !== ''" v-html="matchFind(data.lastName)"></span>
              <span v-else>-</span>
            </div>
            <div class="card_col text-gray4 heading-6">
              <span v-if="data.notes !== ''" class="overFlowParaA" v-html="matchFind(data.notes)"></span>
              <span v-else>-</span>
            </div>
            <div class="table_col2 text-gray4 heading-6" >
              <span v-if="data.shiftStartTime !== ''" v-html="dateFilter(data.shiftStartTime, 'scheduleDate')" ></span>
              <span v-if="data.shiftStartTime !== ''">(</span><span v-if="data.shiftStartTime !== ''" v-html="dateFilter(data.shiftStartTime, 'scheduleTime')" ></span>
              <span v-if="data.shiftEndTime !== ''">-</span>
              <span v-if="data.shiftEndTime !== ''" v-html="dateFilter(data.shiftEndTime, 'scheduleTime')" ></span><span>)</span>
            </div>
          </div>
        </div>
        <div v-if="mobileView">
          <div style="overflow-y: auto; white-space: nowrap;" class="" >
            <div v-for="(data, index) in shiftList" :key="index" @click="redirectTo(data)"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer">
              <div class="">
                <div class="flex justify-end items-center">
                  <p class="text-gray4 heading-6">
                    <span v-if="data.shiftStartTime !== ''" v-html="dateFilter(data.shiftStartTime, 'scheduleDate')" ></span>
                    <span v-if="data.shiftStartTime !== ''">(</span><span v-if="data.shiftStartTime !== ''" v-html="dateFilter(data.shiftStartTime, 'scheduleTime')" ></span>
                    <span v-if="data.shiftEndTime !== ''">-</span>
                    <span v-if="data.shiftEndTime !== ''" v-html="dateFilter(data.shiftEndTime, 'scheduleTime')" ></span><span>)</span>
                  </p>
                </div>
                <p class="text-gray4 heading-6">{{ data.firstName }}</p>
                <p class="text-gray4 heading-6 overFlowParaA" v-html="matchFind(data.notes)"></p>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="shiftList.length === 0 && isFirstLoad">
        <NoRecords :alertMessage="` No Shift found`" />
      </div>
    </div>
  </div>
</template>
<script>
import NoRecords from '@/View/components/noRecords.vue'
var moment = require('moment')
import MyJobApp from '@/api/MyJobApp.js'
export default {
  components: {
    NoRecords
  },
  data() {
    return {
      shiftList: [],
      searchValue: '',
      isFirstLoad: false,
      mobileView: false,
    };
  },
  watch: {
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.$root.$on("shiftSearchingCall", (response) => {
      this.searchValue = response
      this.shiftListApi (response)
    });
  },
  methods: {
    redirectTo (data) {
      this.$router.push({name: 'shiftDetail', params: {shiftId: data.timeSheetId}})
      this.$root.$emit('closeGlobalSearchDialog')
    },
    shiftListApi (data) {
      this.isFirstLoad = false
      MyJobApp.GlobalShiftSearch(
        0,
        500,
        data,
        response => {
          this.shiftList = response.Data.tableRow !== null ? response.Data.tableRow : []
          this.$root.$emit('shiftSearchingCount', response.Data.count)
          this.isFirstLoad = true
        },
        (error) => {
          this.isFirstLoad = true
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    dateFilter (dateTime, value) {
      let checkDate = new Date(dateTime)
      let timeValue = moment(checkDate, 'ddd DD-MMM-YYYY, hh:mm a').format('hh:mm a')
      let todayDate = new Date().setHours(0, 0, 0, 0)
      var fullDate = moment(dateTime).format('MMM DD, ')
      let dayName = moment(dateTime).format('ddd')
      dateTime = new Date(dateTime).toUTCString()
      let requestDt = dateTime
      requestDt = new Date(requestDt).setHours(0, 0, 0, 0)
      if (value === 'scheduleDate') {
        return fullDate + ' ' + dayName
      } else if (value === 'scheduleTime') {
        return timeValue
      } else {
        if (moment(todayDate).diff(requestDt, 'days') === 0) {
          return 'Today ' + timeValue
        }
        let difDay = moment(todayDate).diff(requestDt, 'days')
        if (difDay > 1) {
          return fullDate + ' ' + timeValue
        }
        if (difDay === 1) {
          return 'Yesterday ' + timeValue
        }
      }
    },
    // showDetail () {
    //   this.$root.$emit('closeGlobalSearchDialog')
    // },
    resizeWindowHandler() {
      if (window.innerWidth < 1194) {
        this.mobileView = true
      } else {
        this.mobileView = false
      }
    },
    matchFind (str) {
      if (this.searchValue === null || this.searchValue === undefined || this.searchValue === ' ') {
        return
      }
      let searchStrArray = this.searchValue.split(' ')
      let replceString = str.trim()
      for (let i = 0; i < searchStrArray.length; i++) {
        if (searchStrArray[i].trim() !== '' && searchStrArray[i].trim() !== 'em') {
          replceString = replceString.replace(new RegExp(searchStrArray[i], 'gi'), match => {
            return '<em style="background-color:#78c8f294">' + match + '</em>'
          })
        }
      }
      return replceString
    }
  },
  beforeDestroy() {
    this.$root.$off('shiftSearchingCall')
  },
};
</script>
<style scoped>
.card_col {
  min-width: 300px !important;
  width: 500px !important;
  max-width: 500px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.table_col2 {
  min-width: 130px !important;
  width: 240px !important;
  max-width: 240px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.table_col1 {
  min-width: 130px !important;
  width: 140px !important;
  max-width: 140px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
  
  