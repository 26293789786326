import Vue from 'vue'
import Router from 'vue-router'
import { HRM } from '@/View/router/index.js'
import { endUser } from '@/View/endUserNewFlow/router/index'
Vue.use(Router)

export const constantRouterMap = [
    {
        path: '/',
        hidden: true,
        redirect: '/login'
    },
    { // Login Page
        path: '/login',
        component: () =>
            import ('@/View/login/login.vue'),
        hidden: true,
        name: 'login',
        meta: {
            title: 'login',
            selected: false,
            loadPage: 'login',
            permissionList: []
        }
    },
    { // Login Page
      path: '/registerasowner',
      component: () => import('@/View/login/registerAsOwner.vue'),
      hidden: true,
      name: 'registerAsOwner',
      meta: {
        title: 'Register As Owner',
        selected: false,
        loadPage: 'login',
        permissionList: []
      }
    },
    {
        path: '/jd/:infoId',
        name: 'jd',
        component: () => import('@/View/endUsers/requestJobInfo.vue'),
        hidden: true,
        meta: {
          title: 'ShareInformation',
          selected: false,
          permissionList: [],
          loadPage: 'jd',
        }
      },
    {
        path: '/rsd/:infoId',
        name: 'rsd',
        component: () => import('@/View/endUsers/requestJobInfo.vue'),
        hidden: true,
        meta: {
          title: 'ShareInformation',
          selected: false,
          permissionList: [],
          loadPage: 'rsd',
        }
      },
    {
        path: '/dis/:hashId',
        name: 'DisAssociationHash',
        component: () => import('@/View/endUsers/dissAssociation.vue'),
        hidden: true,
        meta: {
          title: 'DisAssociation',
          selected: false,
          permissionList: [],
          loadPage: 'DisAssociationHash',
        }
      },
      {
        path: '/i/:hashId',
        name: 'InvoiceDetailHash',
        component: () => import('@/View/endUsers/invoice.vue'),
        hidden: true,
        meta: {
          title: 'Invoice Detail',
          selected: false,
          permissionList: [],
          loadPage: 'InvoiceDetailHash',
        }
      },
      {
        path: '/invoicepPreview/:hashId',
        name: 'DownloadHashInvoice',
        component: () => import('@/View/endUsers/component/invoicePreview.vue'),
        hidden: true,
        meta: {
          title: 'Invoice Download',
          selected: false,
          permissionList: [],
          loadPage: 'DownloadHashInvoice',
        }
      },
      {
        path: '/p/:hashId',
        name: 'PaymentDetailHash',
        component: () => import('@/View/endUsers/payment.vue'),
        hidden: true,
        meta: {
          title: 'Payment Detail',
          selected: false,
          permissionList: [],
          loadPage: 'PaymentDetailHash',
        }
      },
      {
        path: '/paymentPreview/:hashId',
        name: 'DownloadHashPayment',
        component: () => import('@/View/endUsers/component/paymentPreview.vue'),
        hidden: true,
        meta: {
          title: 'Payment Download',
          selected: false,
          permissionList: [],
          loadPage: 'DownloadHashPayment',
        }
      },
      {
        path: '/q/:hashId',
        name: 'QuoteDetailHash',
        component: () => import('@/View/endUsers/quotation.vue'),
        hidden: true,
        meta: {
          title: 'Quote Detail',
          selected: false,
          permissionList: [],
          loadPage: 'QuoteDetailHash',
        }
      },
      {
        path: '/quotePreview/:hashId',
        name: 'DownloadHashQuote',
        component: () => import('@/View/endUsers/component/quotationPreview.vue'),
        hidden: true,
        meta: {
          title: 'Quotation Download',
          selected: false,
          permissionList: [],
          loadPage: 'DownloadHashQuote',
        }
      },
      {
        path: '/j/:hashId',
        name: 'JobDetailHash',
        component: () => import('@/View/endUsers/jobs.vue'),
        hidden: true,
        meta: {
          title: 'Job Detail',
          selected: false,
          permissionList: [],
          loadPage: 'JobDetailHash',
        }
      },
      {
        path: '/jobPreview/:hashId',
        name: 'DownloadHashJob',
        component: () => import('@/View/endUsers/component/jobPreview.vue'),
        hidden: true,
        meta: {
          title: 'Job Download',
          selected: false,
          permissionList: [],
          loadPage: 'DownloadHashJob',
        }
      },
      { // Dashboard object start
        path: '/plan',
        component: () => import('@/View/subscription/index.vue'),
        hidden: true,
        name: 'plan',
        meta: {
          title: 'Plan',
          selected: false,
          permissionList: [],
          loadPage: 'plan',
        }
      },
      { // Dashboard object start
        path: '/emailverify',
        component: () => import('@/View/approval/main.vue'),
        hidden: true,
        name: 'Varify',
        meta: {
          title: 'Varify',
          selected: false,
          permissionList: [],
          loadPage: 'Varify',
        }
      },
      { // Dashboard object start
        path: '/paymentSuccess',
        component: () => import('@/View/subscription/paymentSuccess.vue'),
        hidden: true,
        name: 'paymentSuccess',
        meta: {
          title: 'paymentSuccess',
          selected: false,
          permissionList: [],
          loadPage: 'paymentSuccess',
        }
      },
    { // Dashboard object start
        path: '/',
        component: () =>
            import ('@/View/layout/layout.vue'),
        hidden: true,
        name: 'dashboard',
        meta: {
            title: 'dashboard',
            selected: false,
            permissionList: []
        }
    },
    { // Dashboard object start
        path: '/endUser',
        component: () =>
            import ('@/View/endUserNewFlow/layout.vue'),
        hidden: true,
        name: 'dashboard',
        meta: {
            title: 'dashboard',
            selected: false,
            permissionList: []
        }
    },
    HRM,
    endUser,
]
export default new Router({
    routes: constantRouterMap,
    mode: 'history'
})