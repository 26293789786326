<template>
  <div class="" >
    <div v-if="!mobileView" id="mySidenav" class="navigationWeb bg-white cursor-pointer rounded-lg card w-64 m-2 mt-4">
      
      <div class="pt-4">
        <div 
          v-for="item in menuItems"
          :key="item.path"
          @click="routeTo(item)"
          class="h-12 ico_list d_icon_box items-center mb-3 hover:bg-gray1 text-gray3 hover:text-primary"
          
        >
          <div class="d_icon_child">
            <div :class="item.meta.selected ? 'text-primary ' : ''" class="rounded heading-2">
              <i style="height:30px; width:30px" :class="item.meta.iconClass"></i>
            </div>
          </div>
          <span :class="item.meta.selected ? 'text-primary ' : ''" class="d_items_text  font-bold">{{ item.meta.title }}</span>
        </div>
      </div>
    </div>
    <div v-else class="sv_sidenav_box">
      <div class="absolute top-0 left-0" style="z-index: 100">
        <button @click="smallViewOpenDrawer()" class="card p-2 bg-gray4 rounded-r-xl w-8"> <i class="fas fa-bars text-white"></i></button>
      </div>
      <div v-click-overLay="closeLeftNavDrawer" id="sv_smySidenav" class="sv_sidenav bg-white">
        <div class="pt-4">
        <div 
          v-for="item in menuItems"
          :key="item.path"
          @click="routeTo(item)"
          class="h-12 ico_list d_icon_box items-center mb-4 hover:shadow-lg text-gray3 hover:text-primary" 
        >
          <div class="d_icon_child">
            <div :class="item.meta.selected ? 'text-primary ' : ''" class="rounded heading-2">
              <i style="height:30px; width:30px" :class="item.meta.iconClass"></i>
            </div>
          </div>
          <span :class="item.meta.selected ? 'text-primary ' : ''" class="d_items_text  font-bold">{{ item.meta.title }}</span>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
Vue.directive('click-overLay', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
// import colors from '@/assets/colors.json'
import router from '@/router/index.js'
export default {
  components: {},
  name: "leftNavbar",
  data() {
    return {
      showCall: false,
      mobileView: false,
      extraSmall: false,
      drawerIsOpen: false,
      menuItems: [
        {
          name: 'EndUserJobListing',
          meta: {selected: false, routIconSize: {height: '40px', width: '40px', color: ''},
          iconClass: 'fa-solid fa-briefcase', title: 'Job', loadPage: 'EndUserJobListing'},
        },
        {
          name: 'EndUserVisitListing',
          meta: {selected: false, routIconSize: {height: '50px', width: '50px', color: ''},
          iconClass: 'fas fa-business-time', title: 'Visit', loadPage: 'EndUserVisitListing'},
        },
        {
          name: 'EndUserInvoiceListing',
          meta: {selected: false, routIconSize: {height: '40px', width: '40px', color: ''},
          iconClass: 'fa-solid fa-file-invoice-dollar', title: 'Invoice', loadPage: 'EndUserInvoiceListing'},
        },
        {
          name: 'EndUserQuoteListing',
          meta: {selected: false, routIconSize: {height: '40px', width: '40px', color: ''},
          iconClass: 'fab fa-quora', title: 'Quotation', loadPage: 'EndUserQuoteListing'},
        },
        {
          name: 'EndUserPaymentListing',
          meta: {selected: false, routIconSize: {height: '40px', width: '40px', color: ''},
          iconClass: 'fa-solid fa-dollar-sign', title: 'Payment', loadPage: 'EndUserPaymentListing'},
        },
      ]
    };
  },
  created() {
    this.resizeWindowHandler()
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.resizeWindowHandler();
     
    router.afterEach(() => {
      setTimeout(() => {
        this.setNavigation(this.$route.meta.loadPage)
      }, 200);
    })
    setTimeout(() => {
      this.setNavigation(this.$route.meta.loadPage)
    }, 500);
  },
  watch: {
  },
  methods: {
    smallViewOpenDrawer() {
      if (this.mobileView) {
        setTimeout(() => {
          document.getElementById("sv_smySidenav").style.minWidth = "260px";
          document.getElementById("overlay").style.display = "block";
        }, 10);
      }
      document.getElementById("overlay").style.display = "block";
      document.getElementById("sv_smySidenav").style.minWidth = "260px";
      document.getElementById("menu_items_box").style.left = "275px";
      document.getElementById("new_add_items_box").style.left = "275px";
      document.getElementById("history_list_main_box").style.left = "275px";
      document.getElementById("favorite_add_main_box").style.left = "275px";
      this.showDashboadMenus = false;
      this.showAddNewWorkMenus = false;
      this.showHistory = false;
      this.showAddFavorite = false;
      if (!this.setFavIcon) {
        this.isfavorite = false
        //  console.log('check ====1');
      }
      this.showAvtarMenu = false;
      this.drawerIsOpen = true;
    },
    closeLeftNavDrawer () {
      // document.getElementById("overlay").style.display = "none";
      document.getElementById("sv_smySidenav").style.minWidth = "0px";
    },
    closeDrawer() {
    },
    openDrawer() {
    },
    routeTo(path) {
      console.log("path", path);
      if (this.mobileView) {
        this.closeLeftNavDrawer()
      }
      for (let index = 0; index < this.menuItems.length; index++) {
        // if (this.$route.name === this.menuItems[index].name) {
        if (this.$route.meta.loadPage === this.menuItems[index].meta.loadPage) {
          this.menuItems[index].meta.selected = true;
          // this.menuItems[index].meta.routIconSize.color = colors.white;
        } else {
          this.menuItems[index].meta.selected = false;
          // this.menuItems[index].meta.routIconSize.color = colors.gray2;
        }
      }
      this.$router.push({name: path.name}).catch(()=>{});

    },
    setNavigation (value) {
      console.log('valuevaluevalue', value)
      if (this.$route.meta.title !== '') {
        if (this.$route.meta.title !== 'End User' && this.$route.meta.title !== 'Quote Detail' && this.$route.meta.title !== 'Invoice Detail' && this.$route.meta.title !== 'Payment Detail' && this.$route.meta.title !== 'EndUserPaymentDetail' && this.$route.meta.title !== 'EndUserQuoteDetail' && this.$route.meta.title !== 'EndUserInvoiceDetail' && this.$route.meta.title !== 'EndUserVisitDetail' && this.$route.meta.title !== 'EndUserJobDetail' && this.$route.meta.title !== 'EndUserPaymentListing' && this.$route.meta.title !== 'EndUserQuoteListing' && this.$route.meta.title !== 'EndUserInvoiceListing' && this.$route.meta.title !== 'EndUserJobListing' && this.$route.meta.title !== 'EndUserVisitListing') {
          // document.title = this.$route.meta.title
        }
      }
      for (let index = 0; index < this.menuItems.length; index++) {
        // if (this.$route.name === this.menuItems[index].name) {
          // console.log("vvvvvvvv" value , this.routesList[index].meta);
          console.log('this.menuItems', this.menuItems[index].meta.loadPage)
        if (value === this.menuItems[index].meta.loadPage) {
          this.menuItems[index].meta.selected = true;
        } else {
          this.menuItems[index].meta.selected = false;
        }
      }
      console.log(this.menuItems[0].meta.selected);
    },
    resizeWindowHandler() {
      if (window.innerWidth < 1024) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
      if (window.innerWidth < 600) {
        this.extraSmall = true;
      } else {
        this.extraSmall = false;
      }
    },
  },
  beforeDestroy () {
  }
};
</script>
<style scoped>
#mySidenav {
  width: 55px !important;
  transition: width 0.5s;
}
#mySidenav:hover {
  width: 250px !important;
}
#mySidenav:hover .ico_list{
  display: flex;
  align-items: center;
    /* --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow); */
}
.navigationWeb {
  width: 300px;
  /* min-width: 300px; */
  z-index: 2;
  overflow-x: hidden;
  transition: 0.2s;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>
