<template>
  <div class="card bg-white rounded-xl p-2">
    <div>
      <div style="overflow-y: auto; white-space: nowrap;border-collapse: collapse; " class="relative" v-if="isFirstLoad">
        <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center ">
          <div class="table_col1 uppercase ">Invoice #</div>
          <div class="table_col uppercase ">Notes</div>
          <div class="table_col2 uppercase ">Grand Total</div>
          <div class="table_col uppercase ">Items:</div>
        </div>
        <div class=" flex table_containder min-w-min" style="flex-flow:wrap;">
          <div class="flex p-2 min-w-min rounded w-full my-1 border-b border-gray1 hover:bg-gray-100 cursor-pointer" @click="showJobDetail(data)" v-for="(data, index) in invoiceList" :key="index">
            <div class="flex">
              <div class="table_col1 text-gray4  heading-6">
                <span v-if="data.invoiceNumber !== ''" v-html="'#' + matchFind(data.invoiceNumber)"></span>
                <span v-else>-</span>
              </div>
              <div class="table_col text-gray4 heading-6">
                <span class="overFlowParaA" v-if="data.notes !== ''" v-html="matchFind(data.notes)"></span>
                <span v-else>-</span>
              </div>
              <div class="table_col2 text-gray4 heading-6" >
                <span v-if="data.grandTotal !== ''">{{ data.grandTotal | amountFormaterWithToFix}}</span>
                <span v-else>-</span>
              </div>
            </div>
            <div class="border border-gray2 rounded p-1 max-w-max">
              <div class="flex font-bold text-gray4 border-b heading-7">
                <div class="table_col3 centerLine">
                  <span class="overFlowParaA">Item Code</span>
                </div>
                <div class="table_col3 centerLine rightText">
                  <span class="overFlowParaA">Item Name</span>
                </div>
                <div class="table_col centerLine rightText">
                  <span class="overFlowParaA">Description</span>
                </div>
              </div>
              <div v-for="(dataA, indexB) in data.items.itms" :key="indexB" class="flex hover:bg-gray-100 group cursor-pointer " :class="data.items.itms > 1 ? 'py-0.5' : ''">
                <div class="table_col3">
                  <span class="heading-6 text-text1" v-if="dataA.ic !== ''" v-html="matchFind(dataA.ic)"></span>
                  <span class="heading-6 text-text1 pl-4" v-else>--</span>
                </div>
                <div class="table_col3 rightText">
                  <span class="heading-6 text-text1" v-if="dataA.in !== ''" v-html="matchFind(dataA.in)"></span>
                  <span class="heading-6 text-text1 pl-4" v-else>--</span>
                </div>
                <div class="table_col rightText">
                  <span class="heading-6 text-text1 overFlowParaA" v-if="dataA.id !== ''" v-html="matchFind(dataA.id)"></span>
                  <span class="heading-6 text-text1 pl-4" v-else>--</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="invoiceList.length === 0 && isFirstLoad">
        <NoRecords :alertMessage="` No Invoice found`" />
      </div>
    </div>
  </div>
</template>
<script>
import NoRecords from '@/View/components/noRecords.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
  components: {
    NoRecords
  },
  data() {
    return {
      invoiceList: [],
      searchValue: '',
      isFirstLoad: false,
    };
  },
  watch: {
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.$root.$on("invoiceSearchingCall", (response) => {
      this.searchValue = response
      this.inoiceListApi (response)
    });
  },
  methods: {
    inoiceListApi (data) {
      this.isFirstLoad = false
      MyJobApp.GlobalInvoiceSearch(
        0,
        500,
        data,
        response => {
          let invoiceList = response.Data.tableRow !== null ? response.Data.tableRow : []
          for (let index = 0; index < invoiceList.length; index++) {
            invoiceList[index].items = JSON.parse(invoiceList[index].items)
          }
          this.invoiceList = invoiceList
          this.$root.$emit('invoiceSearchingCount', response.Data.count)
          this.isFirstLoad = true
        },
        (error) => {
          this.isFirstLoad = true
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    resizeWindowHandler() {
      this.screenWidth = window.innerWidth
    },
    showJobDetail (data) {
      this.$router.push({name: 'invoiceDetails', params: {invoiceId: data.invoiceId}})
      this.$root.$emit('closeGlobalSearchDialog')
    },
    matchFind (str) {
      if (this.searchValue === null || this.searchValue === undefined || this.searchValue === ' ') {
        return
      }
      let searchStrArray = this.searchValue.split(' ')
      let replceString = str.trim()
      for (let i = 0; i < searchStrArray.length; i++) {
        if (searchStrArray[i].trim() !== '' && searchStrArray[i].trim() !== 'em') {
          replceString = replceString.replace(new RegExp(searchStrArray[i], 'gi'), match => {
            return '<em style="background-color:#78c8f294">' + match + '</em>'
          })
        }
      }
      return replceString
    }
  },
  beforeDestroy() {
    this.$root.$off('invoiceSearchingCall')
  },
};
</script>
<style scoped>
.table_col1 {
  min-width: 80px !important;
  width: 96px !important;
  max-width: 96px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.table_col {
  min-width: 300px !important;
  width: 400px !important;
  max-width: 400px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.table_col2 {
  min-width: 130px !important;
  width: 170px !important;
  max-width: 170px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.table_col3 {
  min-width: 130px !important;
  width: 220px !important;
  max-width: 220px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
  
  