<template>
  <div class="bg-screen_bg card dialogFullScreen">
    <div class="">
      <div class="card bg-white rounded-xl p-4 m-4 mb-2">
        <div class="">
          <div class="flex flex-wrap justify-between items-center">
            <div class="w-72">
              <div style="display:flex; min-height:42px; max-height:42px" :class="isfocus ? 'border border-primary' : 'border border-gray2'" class="search_customer_box  text-gray-500 bg-white rounded-md text-sm px-2 py-1 items-center">
                  <span @click="callSearch()" class="mr-2 cursor-pointer"><img height="20px" width="20px" src="@/assets/images/svg/gray-search.svg" alt=""></span>
                  <input class="focus:outline-none w-36 sm:w-full"
                  type="search"
                  name=""
                  placeholder="Search..."
                  id="search_Input"
                  v-model="inputValue"
                  autocomplete="OFF"
                  :autofocus="isfocus"
                  @input="callSearch"
                  @focus="isfocus = true"
                  @blur="isfocus = false"
                  >
              </div>
              <!-- <SearchTextInput @searchCall="searchCall" :placeholderText="`Search...`" /> -->
              <p class="label_css absolute card bg-white p-2" v-if="inputValue.length < 2 && isfocus">*Please enter 2 characters or more.</p>
            </div>
            <div class="">
              <button class="bg-primary px-3 py-2 rounded-full hover:shadow-xl" @click="closeGlobalsearch()">
                <i class="fas fa-times text-white h-5 w-5 pt-1"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card bg-white rounded-xl p-4 m-4 mt-0">
        <!-- <div class="sticky top-0 bg-white" style="z-index: 10000 !important"> -->
        <div>
          <div v-if="tabTitle !== null"
            class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <ul class="flex flex-wrap -mb-px">
              <li v-for="(tab, index) in tabTitle" :key="index" class="mr-2 cursor-pointer" @click="tabsSet(index)">
                <span href="#"
                  :class="`${tab.active ? 'text-primary  border-primary' : 'border-transparent'} border-b-2 inline-block px-4 py-2 rounded-t-lg active dark:text-blue-500 dark:border-blue-500`"
                  aria-current="page">
                  <div>{{ tab.name }}</div>
                  <div>{{ tab.count }}</div>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-2" v-show="selectedName === 'Job'  && inputValue.length >= 2" >
          <Jobs />
        </div>
        <div class="mt-2" v-show="selectedName === 'Visit'  && inputValue.length >= 2">
          <Visit />
        </div>
        <div class="mt-2" v-show="selectedName === 'Lead'  && inputValue.length >= 2" >
          <Leads />
        </div>
        <div class="mt-2" v-show="selectedName === 'Invoice'  && inputValue.length >= 2">
          <Invoices />
        </div>
        <div class="mt-2" v-show="selectedName === 'Customer'  && inputValue.length >= 2">
          <Customer />
        </div>
        <div class="mt-2" v-show="selectedName === 'Payment'  && inputValue.length >= 2">
          <Payment />
        </div>
        <div class="mt-2" v-show="selectedName === 'Shift'  && inputValue.length >= 2">
          <Shift />
        </div>
        <div class="mt-2" v-show="selectedName === 'Quote'  && inputValue.length >= 2">
          <Quotes />
        </div>
        <div class="mt-2" v-show="selectedName === 'Request'  && inputValue.length >= 2">
          <Requests />
        </div>
        <div class="mt-2" v-show="selectedName === 'Task'  && inputValue.length >= 2">
          <Tasks />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {FilterPermissions} from '@/utils/Permissions.js'
import deboucneMixin from '@/mixins/debounce.js'
// import SearchTextInput from '@/View/components/searchTextInput.vue'
import MyJobApp from '@/api/MyJobApp.js'
import Visit from '@/View/components/searchList/visits.vue';
import Jobs from '@/View/components/searchList/jobs.vue';
import Invoices from '@/View/components/searchList/invoices.vue';
import Customer from '@/View/components/searchList/customers.vue';
import Payment from '@/View/components/searchList/payments.vue';
import Shift from '@/View/components/searchList/shifts.vue';
import Quotes from '@/View/components/searchList/quotes.vue';
import Requests from '@/View/components/searchList/requests.vue';
import Tasks from '@/View/components/searchList/tasks.vue';
import Leads from '@/View/components/searchList/leads.vue';
export default {
  mixins: [deboucneMixin],
  components: {
    Jobs,
    Requests,
    Quotes,
    Shift,
    Tasks,
    Visit,
    Invoices,
    Payment,
    Customer,
    Leads
    // SearchTextInput,
  },
  data() {
    return {
      invoiceList: [],
      invoiceCount: 0,
      customerList: [],
      customerCount: 0,
      paymenytList: [],
      paymenytCount: 0,
      shiftList: [],
      shiftCount: 0,
      quotationList: [],
      quotationCount: 0,
      activeIndex: 0,
      inputValue: '',
      isfocus: true,
      selectedName: 'Job',
      tabTitle: [],
      tabs: [
        { name: 'Job', active: true, count: '--', canViewPermission: FilterPermissions('job') },
        { name: 'Visit', active: false, count: '--', canViewPermission: true },
        { name: 'Lead', active: false, count: '--' , canViewPermission: FilterPermissions('lead')},
        { name: 'Invoice', active: false, count: '--' , canViewPermission: FilterPermissions('invoice')},
        { name: 'Customer', active: false, count: '--' , canViewPermission: FilterPermissions('customer')},
        { name: 'Payment', active: false, count: '--' , canViewPermission: true},
        { name: 'Shift', active: false, count: '--' , canViewPermission: FilterPermissions('shift')},
        { name: 'Quote', active: false, count: '--' , canViewPermission: FilterPermissions('quote')},
        { name: 'Request', active: false, count: '--' , canViewPermission: FilterPermissions('request')},
        { name: 'Task', active: false, count: '--' , canViewPermission: FilterPermissions('task')},
      ],
      getLeadListDebounce: null,
    };
  },
  watch: {
    inputValue : {
      handler () {
        if (this.inputValue.length < 2) {
          for (let index = 0; index < this.tabTitle.length; index++) {
            this.tabTitle[index].count = '--'
          }
        }
      }
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    for (let index = 0; index < this.tabs.length; index++) {
      if (this.tabs[index].canViewPermission) {
        this.tabTitle.push(this.tabs[index])
      }
    }
    if (this.tabTitle.length > 0) {
      this.tabTitle[0].active = true
      this.selectedName = this.tabTitle[0].name
    }
    document.body.style = 'overflow: hidden;'
    this.$root.$on("jobSearchingCount", (response) => {
      this.tabTitle.forEach(element => {
        if (element.name === 'Job') {
          element.count = response
        }
      });
    });
    this.$root.$on("visitSearchingCount", (response) => {
      this.tabTitle.forEach(element => {
        if (element.name === 'Visit') {
          element.count = response
        }
      });
    });
    this.$root.$on("leadSearchingCount", (response) => {
      this.tabTitle.forEach(element => {
        if (element.name === 'Lead') {
          element.count = response
        }
      });
    });
    this.$root.$on("invoiceSearchingCount", (response) => {
      this.tabTitle.forEach(element => {
        if (element.name === 'Invoice') {
          element.count = response
        }
      });
    });
    this.$root.$on("custSearchingCount", (response) => {
      this.tabTitle.forEach(element => {
        if (element.name === 'Customer') {
          element.count = response
        }
      });
    });
    this.$root.$on("paymentSearchingCount", (response) => {
      this.tabTitle.forEach(element => {
        if (element.name === 'Payment') {
          element.count = response
        }
      });
    });
    this.$root.$on("shiftSearchingCount", (response) => {
      this.tabTitle.forEach(element => {
        if (element.name === 'Shift') {
          element.count = response
        }
      });
    });
    this.$root.$on("quotesSearchingCount", (response) => {
      this.tabTitle.forEach(element => {
        if (element.name === 'Quote') {
          element.count = response
        }
      });
    });
    this.$root.$on("requestSearchingCount", (response) => {
      this.tabTitle.forEach(element => {
        if (element.name === 'Request') {
          element.count = response
        }
      });
    });
    this.$root.$on("taskSearchingCount", (response) => {
      this.tabTitle.forEach(element => {
        if (element.name === 'Task') {
          element.count = response
        }
      });
    });
    this.getLeadListDebounce = this.debounce(function () {
      if (this.inputValue.length >= 2) {
        this.getallCount()
        if (this.selectedName === 'Job') {
          this.$root.$emit('jobSearchingCall', this.inputValue)
        } else if (this.selectedName === 'Visit') {
          this.$root.$emit('visitsSearchingCall', this.inputValue)
        } else if (this.selectedName === 'Lead') {
          this.$root.$emit('leadSearchingCall', this.inputValue)
        } else if (this.selectedName === 'Invoice') {
          this.$root.$emit('invoiceSearchingCall', this.inputValue)
        } else if (this.selectedName === 'Customer') {
          this.$root.$emit('customerSearchingCall', this.inputValue)
        } else if (this.selectedName === 'Payment') {
          this.$root.$emit('paymentSearchingCall', this.inputValue)
        } else if (this.selectedName === 'Shift') {
          this.$root.$emit('shiftSearchingCall', this.inputValue)
        } else if (this.selectedName === 'Quote') {
          this.$root.$emit('quotesSearchingCall', this.inputValue)
        } else if (this.selectedName === 'Request') {
          this.$root.$emit('requestSearchingCall', this.inputValue)
        } else if (this.selectedName === 'Task') {
          this.$root.$emit('taskSearchingCall', this.inputValue)
        }
      }
      //   this.$root.$emit('jobSearchingCall', this.inputValue)
      //   this.$root.$emit('invoiceSearchingCall', this.inputValue)
      //   this.$root.$emit('customerSearchingCall', this.inputValue)
      //   this.$root.$emit('paymentSearchingCall', this.inputValue)
      //   this.$root.$emit('shiftSearchingCall', this.inputValue)
      //   this.$root.$emit('quotesSearchingCall', this.inputValue)
      //   this.$root.$emit('leadSearchingCall', this.inputValue)
      // }
    }, 500)
  },
  methods: {
    callSearch() {
      this.getLeadListDebounce()
    },
    tabsSet(index) {
      this.tabTitle.forEach((element, i) => {
        if (index === i) {
          element.active = true
          this.activeIndex = i
          this.selectedName = element.name
        } else {
          element.active = false
        }
      });
      console.log('this.selectedName', this.selectedName)
      if (this.inputValue.length >= 2) {
        if (this.selectedName === 'Job') {
          this.$root.$emit('jobSearchingCall', this.inputValue)
        } else if (this.selectedName === 'Visit') {
          this.$root.$emit('visitsSearchingCall', this.inputValue)
        } else if (this.selectedName === 'Lead') {
          this.$root.$emit('leadSearchingCall', this.inputValue)
        } else if (this.selectedName === 'Invoice') {
          this.$root.$emit('invoiceSearchingCall', this.inputValue)
        } else if (this.selectedName === 'Customer') {
          this.$root.$emit('customerSearchingCall', this.inputValue)
        } else if (this.selectedName === 'Payment') {
          this.$root.$emit('paymentSearchingCall', this.inputValue)
        } else if (this.selectedName === 'Shift') {
          this.$root.$emit('shiftSearchingCall', this.inputValue)
        } else if (this.selectedName === 'Quote') {
          this.$root.$emit('quotesSearchingCall', this.inputValue)
        } else if (this.selectedName === 'Request') {
          this.$root.$emit('requestSearchingCall', this.inputValue)
        } else if (this.selectedName === 'Task') {
          this.$root.$emit('taskSearchingCall', this.inputValue)
        }
      }
      // this.getLeadListDebounce()
    },
    getallCount () {
      MyJobApp.GlobalSearchCount(
        0,
        500,
        this.inputValue,
        response => {
          for (let index = 0; index < this.tabTitle.length; index++) {
            if (this.tabTitle[index].name === 'Job') {
              this.tabTitle[index].count = response.Data.jobDataCount
            } else if (this.tabTitle[index].name === 'Visit') {
              this.tabTitle[index].count = response.Data.visitDataCount
            } else if (this.tabTitle[index].name === 'Lead') {
              this.tabTitle[index].count = response.Data.leadDataCount
            } else if (this.tabTitle[index].name === 'Invoice') {
              this.tabTitle[index].count = response.Data.invoiceDataCount
            } else if (this.tabTitle[index].name === 'Customer') {
              this.tabTitle[index].count = response.Data.customerDataCount
            } else if (this.tabTitle[index].name === 'Payment') {
              this.tabTitle[index].count = response.Data.paymentDataCount
            } else if (this.tabTitle[index].name === 'Shift') {
              this.tabTitle[index].count = response.Data.shiftDataCount
            } else if (this.tabTitle[index].name === 'Quote') {
              this.tabTitle[index].count = response.Data.quotationDataCount
            } else if (this.tabTitle[index].name === 'Request') {
              this.tabTitle[index].count = response.Data.requestDataCount
            } else if (this.tabTitle[index].name === 'Task') {
              this.tabTitle[index].count = response.Data.taskDataCount
            } 
          }
          console.log('this.tabTitle', this.tabTitle)
          // this.tabTitle[0].count = response.Data.jobDataCount
          // this.tabTitle[1].count = response.Data.visitDataCount
          // this.tabTitle[2].count = response.Data.leadDataCount
          // this.tabTitle[3].count = response.Data.invoiceDataCount
          // this.tabTitle[4].count = response.Data.customerDataCount
          // this.tabTitle[5].count = response.Data.paymentDataCount
          // this.tabTitle[6].count = response.Data.shiftDataCount
          // this.tabTitle[7].count = response.Data.quotationDataCount
          // this.tabTitle[8].count = response.Data.requestDataCount
          // this.tabTitle[9].count = response.Data.taskDataCount
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    closeGlobalsearch() {
      document.body.style = 'overflow: visible;'
      this.$root.$emit('closeGlobalSearchDialog')
    },
    resizeWindowHandler() {
      this.screenWidth = window.innerWidth
    },
  },
  beforeDestroy() {
    this.$root.$off("custSearchingCount");
    this.$root.$off("jobSearchingCount");
    this.$root.$off("paymentSearchingCount");
    this.$root.$off("shiftSearchingCount");
    this.$root.$off("invoiceSearchingCount");
    this.$root.$off("quotesSearchingCount");
    this.$root.$off("taskSearchingCount");
    this.$root.$off("requestSearchingCount");
    this.$root.$off("visitSearchingCount");
    this.$root.$off("leadSearchingCount");
  },
};
</script>
<style scoped>
.dialogFullScreen {
  position: fixed;
  height: 100%;
  width: 100%;
  min-width: 80px;
  z-index: 400 !important;
  top: 0;
  overflow-x: hidden;
  transition: 0.5s;
  border-radius: 0px !important;
  right: 0;
}</style>
  
  