<template>
  <div class="card bg-white rounded-xl p-2">
    <div>
      <div style="overflow-y: auto; white-space: nowrap;border-collapse: collapse; " class="relative" v-if="isFirstLoad">
        <div class="text-gray4  min-w-full border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center" v-if="!mobileView">
          <div class="column_val2 uppercase ">Job #</div>
          <div class="column_val1 uppercase ">Job Title</div>
          <div class="column_val1 uppercase ">Company Name</div>
          <div class="table_col uppercase ">Job Description</div>
          <div class="uppercase ">Notes</div>
        </div>
        <div class=" flex table_containder min-w-min" style="flex-flow:wrap;" v-if="!mobileView">
          <div class="flex p-2 cursor-pointer min-w-min  rounded w-full my-1 border-b border-gray1 hover:bg-gray-100" @click="showJobDetail(data.jobId)" v-for="(data, index) in jobList" :key="index">
            <div class="flex">
              <div class="column_val2 text-gray4 heading-6">
                <span v-if="data.jobNumber !== ''" v-html="'#' + matchFind(data.jobNumber)"></span>
                <span v-else>-</span>
              </div>
              <div class="column_val1 text-gray4 heading-6">
                <span v-if="data.jobTitle !== ''" v-html="matchFind(data.jobTitle)"></span>
                <span v-else>-</span>
              </div>
              <div class="column_val1 text-gray4 heading-6" >
                <span v-if="data.companyName !== ''"  v-html="matchFind(data.companyName)"></span>
                <span v-else>-</span>
              </div>
              <div class="table_col text-gray4 heading-6" >
                <span v-if="data.jobDescription !== ''" class="overFlowParaA"  v-html="matchFind(data.jobDescription)"></span>
                <span v-else>-</span>
              </div>
              <div class="border border-gray2 rounded p-1 max-w-max" v-if="data.jobNoteList.noteList !== null">
                <div class="flex font-bold text-gray4 border-b heading-7">
                  <div class="column_val2 centerLine">
                    <span class="overFlowParaA">Date</span>
                  </div>
                  <div class="table_col centerLine ">
                    <span class="overFlowParaA">Note</span>
                  </div>
                </div>
                <div v-for="(dataA, indexB) in data.jobNoteList.noteList" :key="indexB" class="flex hover:bg-gray-100 group cursor-pointer " :class="data.jobNoteList.noteList > 1 ? 'py-0.5' : ''">
                  <div class="column_val2">
                    <span class="heading-6 text-text1" v-if="dataA.addedOn !== ''" >{{dataA.addedOn | formatDataeTime }}</span>
                    <span class="heading-6 text-text1 pl-4" v-else>--</span>
                  </div>
                  <div class="table_col ">
                    <span class="heading-6 text-text1 overFlowParaA" v-if="dataA.notes !== ''" v-html="matchFind(dataA.notes)"></span>
                    <span class="heading-6 text-text1 pl-4" v-else>--</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="mobileView">
          <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="isFirstLoad">
            <div v-for="(data, index) in jobList" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="showJobDetail(data.jobId)">
              <div class="">
                <div class="flex justify-between items-center">
                  <p class="text-gray4 heading-5" v-html="'#' + matchFind(data.jobNumber)"></p>
                </div>
                  <p class="text-gray4 heading-6" v-html="matchFind(data.jobTitle)"></p>
                  <p class="text-gray4 heading-6" v-html="matchFind(data.companyName)"></p>
                  <p class="text-gray4 heading-6 overFlowParaA" v-html="matchFind(data.jobDescription)"></p>
                  <div class="card p-2" v-if="data.jobNoteList.noteList !== null">
                    <span class="text-gray4 heading-6">Notes:</span>
                    <div v-for="(dataA, indexB) in data.jobNoteList.noteList" :key="indexB" class="border-b py-0.5">
                      <span class="heading-6 text-text1" v-if="dataA.addedOn !== ''" >{{dataA.addedOn | formatDataeTime }}</span>
                      <span class="heading-6 text-text1 overFlowParaA" v-if="dataA.notes !== ''" v-html="matchFind(dataA.notes)"></span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="jobList.length === 0 && isFirstLoad">
        <NoRecords :alertMessage="` No Job found`" />
      </div>
    </div>
  </div>
</template>
<script>
import NoRecords from '@/View/components/noRecords.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
  components: {
    NoRecords
  },
  data() {
    return {
      jobList: [],
      searchValue: '',
      isFirstLoad: false,
      mobileView: false,
    };
  },
  watch: {
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.$root.$on("jobSearchingCall", (response) => {
      this.searchValue = response
      this.jobSearchList (response)
    });
  },
  methods: {
    jobSearchList (data) {
      this.isFirstLoad = false
      MyJobApp.GlobalJobSearch(
        0,
        500,
        data,
        response => {
          this.jobList = response.Data.tableRow !== null ? response.Data.tableRow : []
          this.$root.$emit('jobSearchingCount', response.Data.count)
          this.isFirstLoad = true
        },
        (error) => {
          this.isFirstLoad = true
           this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    showJobDetail (id) {
      this.$router.push({name: 'jobDetail', params: {jobId: id}})
      this.$root.$emit('closeGlobalSearchDialog')
    },
    resizeWindowHandler() {
      this.screenWidth = window.innerWidth
      if (window.innerWidth < 1194) {
        this.mobileView = true
      } else {
        this.mobileView = false
      }
    },
    matchFind (str) {
      if (this.searchValue === null || this.searchValue === undefined || this.searchValue === ' ') {
        return
      }
      let searchStrArray = this.searchValue.split(' ')
      let replceString = str.trim()
      for (let i = 0; i < searchStrArray.length; i++) {
        if (searchStrArray[i].trim() !== '' && searchStrArray[i].trim() !== 'em') {
          replceString = replceString.replace(new RegExp(searchStrArray[i], 'gi'), match => {
            return '<em style="background-color:#78c8f294">' + match + '</em>'
          })
        }
      }
      return replceString
    }
  },
  beforeDestroy() {
    this.$root.$off('jobSearchingCall')
  },
};
</script>
<style scoped>
.column_val1 {
  min-width: 250px !important;
  width: 250px !important;
  max-width: 250px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.column_val2 {
  min-width: 80px !important;
  width: 80px !important;
  max-width: 80px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.column_val2 {
  min-width: 180px !important;
  width: 180px !important;
  max-width: 80px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.table_col {
  min-width: 300px !important;
  width: 400px !important;
  max-width: 400px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
  
  