<template>
  <div class="card bg-white rounded-xl p-2">
    <div>
      <div style="overflow-y: auto; white-space: nowrap;border-collapse: collapse; " class="relative" v-if="isFirstLoad">
        <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center" v-if="!mobileView">
          <div class="table_col3 uppercase ">Task #</div>
          <div class="table_col1 uppercase ">Title</div>
          <div class="table_col2  uppercase">Task Date</div>
          <div class="table_col2  uppercase">Task Start</div>
          <div class="table_col2  uppercase">Task End</div>
        </div>
        <div class=" flex table_containder min-w-min" style="flex-flow:wrap;" v-if="!mobileView">
          <div class="flex p-2 min-w-min rounded w-full my-1 border-b border-gray1 hover:bg-gray-100 cursor-pointer" v-for="(data, index) in shiftList" :key="index" @click="redirectTo(data)">
            <div class="table_col3  text-gray4 heading-6">
              <span># {{data.taskid}}</span>
            </div>
            <div class="table_col1  text-gray4 heading-6">
              <span v-if="data.tasktitle !== ''" v-html="matchFind(data.tasktitle)"></span>
              <span v-else>-</span>
            </div>
            <div class="table_col2  text-gray4 heading-6">
              <span v-if="data.taskdate !== ''">{{ data.taskdate | formatDataeTime}}</span>
              <span v-else>-</span>
            </div>
            <div class="table_col2  text-gray4 heading-6">
              <span v-if="data.startdate !== ''">{{ data.startdate | formatDataeTime}}</span>
              <span v-else>-</span>
            </div>
            <div class="table_col2  text-gray4 heading-6">
              <span v-if="data.enddate !== ''">{{ data.enddate | formatDataeTime}}</span>
              <span v-else>-</span>
            </div>
          </div>
        </div>
        <div v-if="mobileView">
          <div style="overflow-y: auto; white-space: nowrap;" class="" >
            <div v-for="(data, index) in shiftList" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer">
              <div class="">
                <p class="text-gray4 heading-6"># {{ data.taskid }}</p>
                <p class="text-gray4 heading-6 overFlowParaA" v-html="matchFind(data.tasktitle)"></p>
                <p class="text-gray4 heading-6 overFlowParaA">Task Date: {{data.taskdate | formatDataeTime}}</p>
                <p class="text-gray4 heading-6 overFlowParaA">Start Date: {{data.startdate | formatDataeTime}}</p>
                <p class="text-gray4 heading-6 overFlowParaA">End Date: {{data.enddate | formatDataeTime}}</p>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="shiftList.length === 0 && isFirstLoad">
        <NoRecords :alertMessage="` No Task found`" />
      </div>
    </div>
  </div>
</template>
<script>
import NoRecords from '@/View/components/noRecords.vue'
var moment = require('moment')
import MyJobApp from '@/api/MyJobApp.js'
export default {
  components: {
    NoRecords
  },
  data() {
    return {
      shiftList: [],
      searchValue: '',
      isFirstLoad: false,
      mobileView: false,
    };
  },
  watch: {
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.$root.$on("taskSearchingCall", (response) => {
      this.searchValue = response
      this.shiftListApi (response)
    });
  },
  methods: {
    redirectTo (data) {
      this.$router.push({name: 'TaskDetail', params: {taskId: data.taskid}, query: {date: data.taskdate}})
      this.$root.$emit('closeGlobalSearchDialog')
    },
    shiftListApi (data) {
      this.isFirstLoad = false
      MyJobApp.GlobalTaskSearch(
        0,
        500,
        data,
        response => {
          this.shiftList = response.Data.tableRow !== null ? response.Data.tableRow : []
          this.$root.$emit('taskSearchingCount', response.Data.count)
          this.isFirstLoad = true
        },
        (error) => {
          this.isFirstLoad = true
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    dateFilter (dateTime, value) {
      let checkDate = new Date(dateTime)
      let timeValue = moment(checkDate, 'ddd DD-MMM-YYYY, hh:mm a').format('hh:mm a')
      let todayDate = new Date().setHours(0, 0, 0, 0)
      var fullDate = moment(dateTime).format('MMM DD, ')
      let dayName = moment(dateTime).format('ddd')
      dateTime = new Date(dateTime).toUTCString()
      let requestDt = dateTime
      requestDt = new Date(requestDt).setHours(0, 0, 0, 0)
      if (value === 'scheduleDate') {
        return fullDate + ' ' + dayName
      } else if (value === 'scheduleTime') {
        return timeValue
      } else {
        if (moment(todayDate).diff(requestDt, 'days') === 0) {
          return 'Today ' + timeValue
        }
        let difDay = moment(todayDate).diff(requestDt, 'days')
        if (difDay > 1) {
          return fullDate + ' ' + timeValue
        }
        if (difDay === 1) {
          return 'Yesterday ' + timeValue
        }
      }
    },
    // showDetail () {
    //   this.$root.$emit('closeGlobalSearchDialog')
    // },
    resizeWindowHandler() {
      if (window.innerWidth < 1194) {
        this.mobileView = true
      } else {
        this.mobileView = false
      }
    },
    matchFind (str) {
      if (this.searchValue === null || this.searchValue === undefined || this.searchValue === ' ') {
        return
      }
      let searchStrArray = this.searchValue.split(' ')
      let replceString = str.trim()
      for (let i = 0; i < searchStrArray.length; i++) {
        if (searchStrArray[i].trim() !== '' && searchStrArray[i].trim() !== 'em') {
          replceString = replceString.replace(new RegExp(searchStrArray[i], 'gi'), match => {
            return '<em style="background-color:#78c8f294">' + match + '</em>'
          })
        }
      }
      return replceString
    }
  },
  beforeDestroy() {
    this.$root.$off('taskSearchingCall')
  },
};
</script>
<style scoped>
.table_col2 {
  min-width: 230px !important;
  width: 240px !important;
  max-width: 240px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.table_col1 {
  min-width: 330px !important;
  width: 340px !important;
  max-width: 340px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.table_col3 {
  min-width: 80px !important;
  width: 100px !important;
  max-width: 100px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
  
  