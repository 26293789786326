
import Api from './API'
import {getCookies} from '@/utils/cookies'
import errorCode from '@/assets/errorCode.json'
// import * as config from '../config.js'
// import { get } from 'http'
// const crypto = require('crypto')

function MyJobModal () {
  if (!(this instanceof MyJobModal)) {
    return new MyJobModal()
  }
}
// ++++++++++++++++++++++++++++ user login and user data api call start ++++++++++++++++++++++++++++
// var keyStr = 'My*W0#kp@pp%!'
// init app api
/*
* staff login api
*/

MyJobModal.prototype.login = async function (
  emailAddress,
  password,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('emailAddress', emailAddress)
  data.append('password', password)
  Api.login(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.forgotPinOtpSend = async function (
  email,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('email', email)
  Api.forgotPinOtpSend(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.forgotPinVerifyOtp = async function (
  regToken,
  otp,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('regToken', regToken)
  data.append('otp', otp)
  Api.forgotPinVerifyOtp(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.updateLoginPin = async function (
  regToken,
  appPin,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('regToken', regToken)
  data.append('appPin', appPin)
  Api.updateLoginPin(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.VerifyAppPin = async function (
  AppPin,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('AppPin', AppPin)
  Api.VerifyAppPin(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        console.log('check 111', data);
        onError(data)
      }  else {
        console.log('check 222', data);
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.cuaromerList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('shortBy', shortBy)
  data.append('sortOrder', sortOrder)
  Api.cuaromerList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.saveNewCustomer = async function (
  customerObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('customerObj', JSON.stringify(customerObj))
  Api.saveNewCustomer(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.saveQuoteDetail = async function (
  quotationData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('quotationData', JSON.stringify(quotationData))
  Api.saveQuoteDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetQuoteDetail = async function (
  quotationId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('quotationId', quotationId)
  Api.GetQuoteDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.deleteQuotation = async function (
  quotationId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('quotationId', quotationId)
  Api.deleteQuotation(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.approveQuotes = async function (
  quotationId,
  signatureData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('quotationId', quotationId)
  data.append('signatureData', signatureData)
  Api.approveQuotes(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.addCustomerAddress = async function (
  addressObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('addressObj', JSON.stringify(addressObj))
  Api.addCustomerAddress(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.DirectLogin = async function (
  uId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('uId', uId)
  Api.DirectLogin(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.updateCustomer = async function (
  updateCustObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('updateCustObj', JSON.stringify(updateCustObj))
  Api.updateCustomer(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.invoiceDetail = async function (
  invoiceId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('invoiceId', JSON.stringify(invoiceId))
  Api.invoiceDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getInvoiceList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  statusIdList,
  customerId,
  from,
  to,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('shortBy', shortBy)
  data.append('sortOrder', sortOrder)
  data.append('statusIdList', statusIdList.toString())
  data.append('customerId', customerId)
  data.append('from', from)
  data.append('to', to)
  Api.getInvoiceList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getPaymentList = async function (
  start,
  length,
  searchVal,
  customerId,
  invoiceId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('customerId', customerId)
  data.append('invoiceId', invoiceId)
  Api.getPaymentList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.paymentmethodList = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  Api.paymentmethodList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.paymentmethodReorder = async function (
  paymentMethods,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('paymentMethods', JSON.stringify(paymentMethods))
  Api.paymentmethodReorder(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.deleteCustomerAddress = async function (
  addressId,
  customerId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('addressId', addressId)
  data.append('customerId', customerId)
  Api.deleteCustomerAddress(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.addUpdateContact = async function (
  contactObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('contactObj', JSON.stringify(contactObj))
  Api.addUpdateContact(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getJobList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  filterType,
  customerId,
  exportData,
  statusIdList,
  userDetailIdList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start' , start)
  data.append('length' , length)
  data.append('searchVal' , searchVal)
  data.append('shortBy' , shortBy)
  data.append('sortOrder' , sortOrder)
  data.append('filterType' , filterType)
  data.append('customerId' , customerId)
  data.append('exportData' , exportData)
  data.append('statusIdList' , statusIdList)
  data.append('userDetailIdList' , userDetailIdList)
  Api.getJobList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getCustomerDetail = async function (
  customerId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('customerId', customerId)
  Api.getCustomerDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getCustomerActivity = async function (
  start,
  length,
  searchVal,
  entityIdList,
  userDetailIdList,
  customerId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('entityIdList', entityIdList)
  data.append('userDetailIdList', userDetailIdList)
  data.append('customerId', customerId)
  Api.getCustomerActivity(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getItemsList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  isActive,
  categoryId,
  isFullDetail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('shortBy', shortBy)
  data.append('sortOrder', sortOrder)
  data.append('isActive', isActive)
  data.append('categoryId', categoryId)
  data.append('isFullDetail', isFullDetail)
  Api.getItemsList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.settingItemDeleteApi = async function (
  itemId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('itemId', itemId)
  Api.settingItemDeleteApi(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SettingCategoryDetail = async function (
  categoryId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('categoryId', categoryId)
  Api.SettingCategoryDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SettingCategoryDelete = async function (
  categoryId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('categoryId', categoryId)
  Api.SettingCategoryDelete(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SettingCheckListItemList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  isActive,
  categoryId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('shortBy', shortBy)
  data.append('sortOrder', sortOrder)
  data.append('isActive', isActive)
  data.append('categoryId', categoryId)
  Api.SettingCheckListItemList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SettingCheckListItemAdd = async function (
  itemData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('itemData', JSON.stringify(itemData))
  Api.SettingCheckListItemAdd(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SettingCheckListItemUpdate = async function (
  itemData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('itemData', JSON.stringify(itemData))
  Api.SettingCheckListItemUpdate(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SettingCheckListItemDelete = async function (
  checkListItemMasterId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('checkListItemMasterId', checkListItemMasterId)
  Api.SettingCheckListItemDelete(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SettingCheckListCategoryList = async function (
  start,
  length,
  searchVal,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  Api.SettingCheckListCategoryList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SettingCheckListCategoryDetail = async function (
  categoryId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('categoryId', categoryId)
  Api.SettingCheckListCategoryDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SettingCheckListCategoryDelete = async function (
  categoryId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('categoryId', categoryId)
  Api.SettingCheckListCategoryDelete(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SettingCheckListCategorySave = async function (
  categoryData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('categoryData', JSON.stringify(categoryData))
  Api.SettingCheckListCategorySave(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.settingAddNewItem = async function (
  itemData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('itemData', JSON.stringify(itemData))
  Api.settingAddNewItem(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.addNewShift = async function (
  missingShiftData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('missingShiftData', JSON.stringify(missingShiftData))
  Api.addNewShift(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.settingEditExistItem = async function (
  itemData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('itemData', JSON.stringify(itemData))
  Api.settingEditExistItem(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SettingCategoryAddEdit = async function (
  categoryData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('categoryData', JSON.stringify(categoryData))
  Api.SettingCategoryAddEdit(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getSettingCategoryList = async function (
  start,
  length,
  searchVal,
  parentCategoryId,
  ignoreParentCategory,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('parentCategoryId', parentCategoryId)
  data.append('ignoreParentCategory', ignoreParentCategory)
  Api.getSettingCategoryList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SettingTaxListing = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('shortBy', shortBy)
  data.append('sortOrder', sortOrder)
  Api.SettingTaxListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getTaxList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('shortBy', shortBy)
  data.append('sortOrder', sortOrder)
  Api.getTaxList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.addNewTax = async function (
  taxList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('taxList', JSON.stringify(taxList))
  Api.addNewTax(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.UpdateTax = async function (
  taxData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('taxData', JSON.stringify(taxData))
  Api.UpdateTax(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SettingDeleteTax = async function (
  taxId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('taxId', taxId)
  Api.SettingDeleteTax(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.checkTaxLink = async function (
  taxId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('taxId', taxId)
  Api.checkTaxLink(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SendSupportData = async function (
  reqObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('reqObj', JSON.stringify(reqObj))
  Api.SendSupportData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SendFeedbackData = async function (
  reqObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('reqObj', JSON.stringify(reqObj))
  Api.SendFeedbackData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.AddNewEmployee = async function (
  employeeData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('employeeData', JSON.stringify(employeeData))
  Api.AddNewEmployee(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.AddNewPermission = async function (
  permissionData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('permissionData', JSON.stringify(permissionData))
  Api.AddNewPermission(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.UpdateEmployee = async function (
  employeeData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('employeeData', JSON.stringify(employeeData))
  Api.UpdateEmployee(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.checkUserNameExistWeb = async function (
  userDetailId,
  userName,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('userDetailId', userDetailId)
  data.append('userName', userName)
  Api.checkUserNameExistWeb(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.sendOTPEmailWeb = async function (
  emailAddress,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('emailAddress', emailAddress)
  Api.sendOTPEmailWeb(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.createInvoice = async function (
  invoiceDetail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('invoiceDetail', JSON.stringify(invoiceDetail))
  Api.createInvoice(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.addUpdatePaymethod = async function (
  payMethodObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('payMethodObj', JSON.stringify(payMethodObj))
  Api.addUpdatePaymethod(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.savePaymentRecord = async function (
  savePaymentObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('savePaymentObj', JSON.stringify(savePaymentObj))
  Api.savePaymentRecord(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.paymethodList = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  Api.paymethodList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.listWithPendingPaymen = async function (
  searchVal,
  shortBy,
  sortOrder,
  customerId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('searchVal', searchVal)
  data.append('shortBy', shortBy)
  data.append('sortOrder', sortOrder)
  data.append('customerId', customerId)
  Api.listWithPendingPaymen(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getLeadlist = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  statusId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('shortBy', shortBy)
  data.append('sortOrder', sortOrder)
  data.append('statusId', statusId)
  Api.getLeadlist(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getQuotationList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  statusIdList,
  customerId,
  from,
  to,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('shortBy', shortBy)
  data.append('sortOrder', sortOrder)
  data.append('statusIdList', statusIdList)
  data.append('customerId', customerId)
  data.append('from', from)
  data.append('to', to)
  Api.getQuotationList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getSiftList = async function (
  userDetailIdList,
  from,
  to,
  exportData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('userDetailIdList', userDetailIdList)
  data.append('from', from)
  data.append('to', to)
  data.append('exportData', exportData)
  Api.getSiftList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getActivitys = async function (
  start,
  length,
  searchVal,
  entityIdList,
  userDetailIdList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('entityIdList', entityIdList)
  data.append('userDetailIdList', userDetailIdList)
  Api.getActivitys(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.deleteNoteAttachment = async function (
  fileVaultId,
  requestType,
  entityDetailId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('fileVaultId', fileVaultId)
  data.append('requestType', requestType)
  data.append('entityDetailId', entityDetailId)
  Api.deleteNoteAttachment(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.addNoteWithAttachment = async function (
  file,
  requestType,
  requestId,
  notes,
  visibleToCustomer,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  for (var i = 0; i < file.length; i++) {
    let fileA = file[i]
    data.append('file', fileA, fileA.name)
  }
  data.append('requestType', requestType)
  data.append('requestId', requestId)
  data.append('notes', notes)
  data.append('visibleToCustomer', visibleToCustomer)
  Api.addNoteWithAttachment(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.updateNoteWithAttach = async function (
  file,
  requestType,
  noteId,
  requestId,
  notes,
  deletedFiles,
  visibleToCustomer,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  for (var i = 0; i < file.length; i++) {
    let fileA = file[i]
    data.append('file', fileA, fileA.name)
  }
  data.append('requestType', requestType)
  data.append('noteId', noteId)
  data.append('requestId', requestId)
  data.append('notes', notes)
  data.append('deletedFiles', JSON.stringify(deletedFiles))
  data.append('visibleToCustomer', visibleToCustomer)
  Api.updateNoteWithAttach(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.deleteNote = async function (
  requestType,
  noteId,
  entityDetailId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('requestType', requestType)
  data.append('noteId', noteId)
  data.append('entityDetailId', entityDetailId)
  Api.deleteNote(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.updateJobDetail = async function (
  file,
  reqType,
  reqObj,
  deleteFileIds,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  for (var i = 0; i < file.length; i++) {
    let fileA = file[i]
    data.append('file', fileA, fileA.name)
  }
  data.append('reqType', reqType)
  data.append('reqObj', JSON.stringify(reqObj))
  data.append('deleteFileIds', deleteFileIds)
  Api.updateJobDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.updateServiceDetail = async function (
  serviceDetail,
  deleteFileIds,
  decisionBy,
  budgetAmount,
  requestId,
  file,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('serviceDetail', serviceDetail)
  data.append('deleteFileIds', deleteFileIds)
  data.append('decisionBy', decisionBy)
  data.append('budgetAmount', budgetAmount)
  data.append('requestId', requestId)
  for (var i = 0; i < file.length; i++) {
    let fileA = file[i]
    data.append('file', fileA, fileA.name)
  }
  Api.updateServiceDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.emailSendBokkconf = async function (
  sendTo,
  ccEmail,
  bccEmail,
  messageBody,
  fromEmail,
  fromName,
  subject,
  customerId,
  isDefault,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('sendTo', JSON.stringify(sendTo))
  data.append('ccEmail', JSON.stringify(ccEmail))
  data.append('bccEmail', JSON.stringify(bccEmail))
  data.append('messageBody', messageBody)
  data.append('fromEmail', fromEmail)
  data.append('fromName', fromName)
  data.append('subject', subject)
  data.append('customerId', customerId)
  data.append('isDefault', isDefault)
  Api.emailSendBokkconf(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.searchContactEmail = async function (
  length,
  searchVal,
  customerId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('length', length )
  data.append('searchVal', searchVal )
  data.append('customerId', customerId )
  Api.searchContactEmail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

MyJobModal.prototype.getUserLocation = async function (
  userDetailIdList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('userDetailIdList', userDetailIdList)
  // data.append('userDetailIdList', userDetailIdList.length > 0 ? JSON.stringify(userDetailIdList) : '')
  Api.getUserLocation(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.HashDisAssociationLink = async function (
  linkHash,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('linkHash', linkHash)
  Api.HashDisAssociationLink(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.HashDisAssociationLinkApprove = async function (
  linkHash,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('linkHash', linkHash)
  Api.HashDisAssociationLinkApprove(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.HashInvoiceDetail = async function (
  linkHash,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('linkHash', linkHash)
  Api.HashInvoiceDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.HashPaymentDetail = async function (
  linkHash,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('linkHash', linkHash)
  Api.HashPaymentDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.HashQuotationDetail = async function (
  linkHash,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('linkHash', linkHash)
  Api.HashQuotationDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.HashJobDetail = async function (
  linkHash,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('linkHash', linkHash)
  Api.HashJobDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.downloadInvoice = async function (
  linkHash,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('linkHash', linkHash)
  Api.downloadInvoice(data).then(data => {
    if (data) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.downloadJob = async function (
  linkHash,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('linkHash', linkHash)
  Api.downloadJob(data).then(data => {
    if (data) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.downloadPayment = async function (
  linkHash,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('linkHash', linkHash)
  Api.downloadPayment(data).then(data => {
    if (data) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.downloadQuotation = async function (
  linkHash,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('linkHash', linkHash)
  Api.downloadQuotation(data).then(data => {
    if (data) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.closeJob = async function (
  jobId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('jobId', jobId)
  Api.closeJob(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.reopenJob = async function (
  jobId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('jobId', jobId)
  Api.reopenJob(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.deleteJob = async function (
  jobId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('jobId', jobId)
  Api.deleteJob(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
// GLOBAL SEARCHING
MyJobModal.prototype.GlobalCustomerSearch = async function (
  start,
  length,
  searchVal,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  Api.GlobalCustomerSearch(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GlobalInvoiceSearch = async function (
  start,
  length,
  searchVal,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  Api.GlobalInvoiceSearch(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GlobalQuoteSearch = async function (
  start,
  length,
  searchVal,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  Api.GlobalQuoteSearch(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GlobalPaymentSearch = async function (
  start,
  length,
  searchVal,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  Api.GlobalPaymentSearch(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GlobalJobSearch = async function (
  start,
  length,
  searchVal,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  Api.GlobalJobSearch(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GlobalShiftSearch = async function (
  start,
  length,
  searchVal,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  Api.GlobalShiftSearch(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GlobalRequestSearch = async function (
  start,
  length,
  searchVal,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  Api.GlobalRequestSearch(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GlobalTaskSearch = async function (
  start,
  length,
  searchVal,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  Api.GlobalTaskSearch(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GlobalVisitSearch = async function (
  start,
  length,
  searchVal,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  Api.GlobalVisitSearch(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.createNewJob = async function (
  jobData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('jobData', JSON.stringify(jobData))
  Api.createNewJob(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.updateJobStep2 = async function (
  jobData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('jobData', JSON.stringify(jobData))
  Api.updateJobStep2(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.updateJobStep3 = async function (
  jobData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('jobData', JSON.stringify(jobData))
  Api.updateJobStep3(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.updateVisitChecklist = async function (
  visitId,
  checkList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('visitId', visitId)
  data.append('checkList', JSON.stringify(checkList))
  Api.updateVisitChecklist(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.addNewJobStepOne = async function (
  jobData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('jobData', JSON.stringify(jobData))
  Api.addNewJobStepOne(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.jobTypelist = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  Api.jobTypelist(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getToolList = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  Api.getToolList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.addJobVisit = async function (
  jobVisitData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('jobVisitData', JSON.stringify(jobVisitData))
  Api.addJobVisit(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.addVisititems = async function (
  visitItemDetail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('visitItemDetail', JSON.stringify(visitItemDetail))
  Api.addVisititems(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.deleteVisitItem = async function (
  visitId,
  itemMappingId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('visitId', visitId)
  data.append('itemMappingId', itemMappingId)
  Api.deleteVisitItem(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.updateVisitItem = async function (
  visitItemDetail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('visitItemDetail', JSON.stringify(visitItemDetail))
  Api.updateVisitItem(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.visitDelete = async function (
  visitId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('visitId', visitId)
  Api.visitDelete(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getSpecificSiftList = async function (
  start,
  length,
  userDetailId,
  shortBy,
  sortOrder,
  statusFilter,
  from,
  to,
  exportData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('userDetailId', userDetailId)
  data.append('shortBy', shortBy)
  data.append('sortOrder', sortOrder)
  data.append('statusFilter', statusFilter)
  data.append('from', from)
  data.append('to', to)
  data.append('exportData', exportData)
  Api.getSpecificSiftList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GlobalSearchCount = async function (
  start,
  length,
  searchVal,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  Api.GlobalSearchCount(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GlobalLeadSearch = async function (
  start,
  length,
  searchVal,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  Api.GlobalLeadSearch(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.startJobVisit = async function (
  visitId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('visitId', visitId)
  Api.startJobVisit(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.stopJobVisit = async function (
  visitId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('visitId', visitId)
  Api.stopJobVisit(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.addUpdateVisitAssig = async function (
  assigneData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('assigneData', JSON.stringify(assigneData))
  Api.addUpdateVisitAssig(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.updateVisitTime = async function (
  reqObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('reqObj', JSON.stringify(reqObj))
  Api.updateVisitTime(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.visitComplete = async function (
  reqType,
  notes,
  visitId,
  checkListItem,
  signatureData,
  isJobClosed,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('reqType', reqType)
  data.append('notes', notes)
  data.append('visitId', visitId)
  data.append('checkListItem', JSON.stringify(checkListItem))
  data.append('signatureData', signatureData)
  data.append('isJobClosed', isJobClosed)
  Api.visitComplete(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.deleteSelfie = async function (
  fileVaultId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('fileVaultId', fileVaultId)
  Api.deleteSelfie(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.EditOrgDetail = async function (
  orgData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('orgData', JSON.stringify(orgData))
  Api.EditOrgDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.EditOrgDetailWithLogo = async function (
  file,
  orgData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('file', file)
  data.append('orgData', JSON.stringify(orgData))
  Api.EditOrgDetailWithLogo(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.DeleteEmployee = async function (
  userDetailId,
  alternateUserId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('userDetailId', userDetailId)
  data.append('alternateUserId', alternateUserId)
  Api.DeleteEmployee(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.orgnizationDelete = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  Api.orgnizationDelete(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.adminStartEndJobForUserWeb = async function (
  requestType,
  visitId,
  userDetailId,
  dateTime,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('requestType', requestType)
  data.append('visitId', visitId)
  data.append('userDetailId', userDetailId)
  data.append('dateTime', dateTime)
  Api.adminStartEndJobForUserWeb(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.addUpdateLead = async function (
  leadData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('leadData', JSON.stringify(leadData))
  Api.addUpdateLead(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.UpdateLeads = async function (
  leadData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('leadData', JSON.stringify(leadData))
  Api.UpdateLeads(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.UpdateLeadsStatus = async function (
  leadId,
  statusId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('leadId', leadId)
  data.append('statusId', statusId)
  Api.UpdateLeadsStatus(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.UpdateRequestStatus = async function (
  requestId,
  statusId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('requestId', requestId)
  data.append('statusId', statusId)
  Api.UpdateRequestStatus(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.addUpdateReqAddress = async function (
  jobAddressDetail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('jobAddressDetail', JSON.stringify(jobAddressDetail))
  Api.addUpdateReqAddress(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.UpdateLeadsContact = async function (
  contactObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('contactObj', JSON.stringify(contactObj))
  Api.UpdateLeadsContact(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.UpdateLeadsAddress = async function (
  addressData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('addressData', JSON.stringify(addressData))
  Api.UpdateLeadsAddress(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getGlobalCustLeadList = async function (
  start,
  length,
  searchVal,
  filterType,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('filterType', filterType)
  Api.getGlobalCustLeadList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.RemoveContacts = async function (
  contactId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('contactId', contactId)
  Api.RemoveContacts(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.RemoveLeadCustomer = async function (
  customerId,
  reqType,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('customerId', customerId)
  data.append('reqType', reqType)
  Api.RemoveLeadCustomer(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.addNewJobLocationWeb = async function (
  jobAddressDetail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('jobAddressDetail', JSON.stringify(jobAddressDetail))
  Api.addNewJobLocationWeb(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.ScheduleJobVisit = async function (
  startDate,
  endDate,
  userDetailIdList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('startDate', startDate)
  data.append('endDate', endDate)
  data.append('userDetailIdList', userDetailIdList)
  Api.ScheduleJobVisit(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.UserScheduleJobVisit = async function (
  startDate,
  endDate,
  userDetailIdList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('startDate', startDate)
  data.append('endDate', endDate)
  data.append('userDetailIdList', userDetailIdList)
  Api.UserScheduleJobVisit(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getLastLocationOfAssignEmp = async function (
  startDate,
  endDate,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('startDate', startDate)
  data.append('endDate', endDate)
  Api.getLastLocationOfAssignEmp(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetUnScheduleJobsOnly = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  Api.GetUnScheduleJobsOnly(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.registerOrganization = async function (
  reqObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('reqObj', JSON.stringify(reqObj))
  Api.registerOrganization(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.CompanySetup = async function (
  reqObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('reqObj', JSON.stringify(reqObj))
  Api.CompanySetup(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.checkEmailNumberExist = async function (
  email,
  number,
  code,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('email', email)
  data.append('number', number)
  data.append('code', code)
  Api.checkEmailNumberExist(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.updateEmailSendOtp = async function (
  regToken,
  newEmail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('regToken', regToken)
  data.append('newEmail', newEmail)
  Api.updateEmailSendOtp(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.registerResendOtp = async function (
  regToken,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('regToken', regToken)
  Api.registerResendOtp(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.registerVerifyOtp = async function (
  regToken,
  otp,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('regToken', regToken)
  data.append('otp', otp)
  Api.registerVerifyOtp(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.registerSetupPin = async function (
  regToken,
  appPin,
  timeZone,
  platFrom,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('regToken', regToken)
  data.append('appPin', appPin)
  data.append('timeZone', timeZone)
  data.append('platFrom', platFrom)
  Api.registerSetupPin(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.latLongUpdateWeb = async function (
  organizationId,
  lat,
  lng,
  fullAddress,
  addressLine1,
  addressLine2,
  city,
  state,
  zip,
  country,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('organizationId', organizationId)
  data.append('lat', lat)
  data.append('lng', lng)
  data.append('addressLine1', addressLine1)
  data.append('addressLine2', addressLine2)
  data.append('city', city)
  data.append('state', state)
  data.append('zip', zip)
  data.append('country', country)
  data.append('fullAddress', fullAddress)
  Api.latLongUpdateWeb(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.addressUpdateWeb = async function (
  orgAddressDetail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('orgAddressDetail', JSON.stringify(orgAddressDetail))
  Api.addressUpdateWeb(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.rejectEmpRequest = async function (
  userDetailId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('userDetailId', userDetailId)
  Api.rejectEmpRequest(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

MyJobModal.prototype.specificShiftDelete = async function (
  timeSheetId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('timeSheetId', timeSheetId)
  Api.specificShiftDelete(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.requestlist = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  filterType,
  customerId,
  exportData,
  statusIdList,
  userDetailIdList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start) 
  data.append('length', length) 
  data.append('searchVal', searchVal) 
  data.append('shortBy', shortBy) 
  data.append('sortOrder', sortOrder) 
  data.append('filterType', filterType) 
  data.append('customerId', customerId) 
  data.append('exportData', exportData) 
  data.append('statusIdList', statusIdList) 
  data.append('userDetailIdList', userDetailIdList) 
  Api.requestlist(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.deleteRequest = async function (
  requestId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('requestId', requestId) 
  Api.deleteRequest(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getAccountingCodeList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('shortBy', shortBy)
  data.append('sortOrder', sortOrder)
  Api.getAccountingCodeList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.AddUpdateAccountingCode = async function (
  accountingCode,
  accountingName,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('accountingCode', accountingCode)
  data.append('accountingName', accountingName)
  Api.AddUpdateAccountingCode(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getExpenseList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  userDetailIdList,
  isMarkPaid,
  fromDate,
  toDate,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('shortBy', shortBy)
  data.append('sortOrder', sortOrder)
  data.append('userDetailIdList', userDetailIdList)
  data.append('isMarkPaid', isMarkPaid)
  data.append('fromDate', fromDate)
  data.append('toDate', toDate)
  Api.getExpenseList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.ExpenseMarkPaid = async function (
  isMarkPaid,
  expenseId,
  paidDate,
  paidNote,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('isMarkPaid', isMarkPaid)
  data.append('expenseId', expenseId)
  data.append('paidDate', paidDate)
  data.append('paidNote', paidNote)
  Api.ExpenseMarkPaid(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.DeleteCode = async function (
  accCodeId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('accCodeId', accCodeId)
  Api.DeleteCode(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getExpenseDetail = async function (
  expenseId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('expenseId', expenseId)
  Api.getExpenseDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getExpenseDelete = async function (
  expenseId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('expenseId', expenseId)
  Api.getExpenseDelete(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.saveExpenseWeb = async function (
  expenseId,
  expenseDate,
  itemName,
  detail,
  totalAmount,
  reimbUserId,
  jobId,
  accountingCode,
  deletedFiles,
  file,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('expenseId', expenseId)
  data.append('expenseDate', expenseDate)
  data.append('itemName', itemName)
  data.append('detail', detail)
  data.append('totalAmount', totalAmount)
  data.append('reimbUserId', reimbUserId)
  data.append('jobId', jobId)
  data.append('accountingCode', accountingCode)
  data.append('deletedFiles', JSON.stringify(deletedFiles))
  for (var i = 0; i < file.length; i++) {
    let fileA = file[i]
    data.append('file', fileA, fileA.name)
  }
  Api.saveExpenseWeb(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.saveRequest = async function (
  saveRequestObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('saveRequestObj', JSON.stringify(saveRequestObj)) 
  Api.saveRequest(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.saveRequestStepOne = async function (
  saveRequestObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('saveRequestObj', JSON.stringify(saveRequestObj)) 
  Api.saveRequestStepOne(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.reqAddStepTwo = async function (
  saveRequestObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('saveRequestObj', JSON.stringify(saveRequestObj)) 
  Api.reqAddStepTwo(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.updateRquestAssignee = async function (
  reqObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('reqObj', JSON.stringify(reqObj)) 
  Api.updateRquestAssignee(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.reqAddStepThree = async function (
  saveRequestObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('saveRequestObj', JSON.stringify(saveRequestObj)) 
  Api.reqAddStepThree(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GenerateJobShareLink = async function (
  jobId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('jobId', jobId)
  Api.GenerateJobShareLink(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GenerateRequestShareLink = async function (
  reqId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('reqId', reqId)
  Api.GenerateRequestShareLink(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.CheckJobTokenLink = async function (
  lh,
  lht,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('lh', lh)
  data.append('lht', lht)
  Api.CheckJobTokenLink(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.UploadJobTokenLinkAtt = async function (
  lh,
  file ,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('lh', lh)
  for (var i = 0; i < file.length; i++) {
    let fileA = file[i]
    data.append('file', fileA, fileA.name)
  }
  Api.UploadJobTokenLinkAtt(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.UploadRequestLinkAtt = async function (
  lh,
  file ,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('lh', lh)
  for (var i = 0; i < file.length; i++) {
    let fileA = file[i]
    data.append('file', fileA, fileA.name)
  }
  Api.UploadRequestLinkAtt(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.overviewReq = async function (
  requestId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('requestId', requestId) 
  Api.overviewReq(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.CompleteAssessment = async function (
  requestId,
  iscomplete,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('requestId', requestId) 
  data.append('iscomplete', iscomplete) 
  Api.CompleteAssessment(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.RequestArchive = async function (
  requestId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('requestId', requestId)
  Api.RequestArchive(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.DeleteReqAssessment = async function (
  requestId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('requestId', requestId)
  Api.DeleteReqAssessment(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetTaskListingForList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  customerId,
  fromDate,
  toDate,
  repeated,
  userDetailIdList,
  isCompleted,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('shortBy', shortBy)
  data.append('sortOrder', sortOrder)
  data.append('customerId', customerId)
  data.append('fromDate', fromDate)
  data.append('toDate', toDate)
  data.append('repeated', repeated)
  data.append('userDetailIdList', userDetailIdList)
  data.append('isCompleted', isCompleted)
  Api.GetTaskListingForList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetTaskList = async function (
  fromDate,
  toDate,
  userDetailIdList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('fromDate', fromDate)
  data.append('toDate', toDate)
  data.append('userDetailIdList', userDetailIdList)
  Api.GetTaskList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetUserTaskList = async function (
  fromDate,
  toDate,
  userDetailIdList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('fromDate', fromDate)
  data.append('toDate', toDate)
  data.append('userDetailIdList', userDetailIdList)
  Api.GetUserTaskList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SaveTaskDetail = async function (
  taskDetail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('taskDetail', JSON.stringify(taskDetail))
  Api.SaveTaskDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.UpdateTaskDetail = async function (
  taskDetail,
  isUpdateFuture,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('taskDetail', JSON.stringify(taskDetail))
  data.append('isUpdateFuture', isUpdateFuture)
  Api.UpdateTaskDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.DeleteTask = async function (
  taskId,
  isDeleteFuture,
  selectedDate,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('taskId', taskId)
  data.append('isDeleteFuture', isDeleteFuture)
  data.append('selectedDate', selectedDate)
  Api.DeleteTask(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.completeTask = async function (
  taskId,
  isTaskCompleted,
  taskSelectedDate,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('taskId', taskId)
  data.append('isTaskCompleted', isTaskCompleted)
  data.append('taskSelectedDate', taskSelectedDate)
  Api.completeTask(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.RequestDragDropEvent = async function (
  scheduleObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('scheduleObj', JSON.stringify(scheduleObj)) 
  Api.RequestDragDropEvent(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

// END USER API NEW

MyJobModal.prototype.GetEndUserJobListWeb = async function (
  authToken,
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  filterType,
  statusIdList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', authToken)
  data.append('start' , start)
  data.append('length' , length)
  data.append('searchVal' , searchVal)
  data.append('shortBy' , shortBy)
  data.append('sortOrder' , sortOrder)
  data.append('filterType' , filterType)
  data.append('statusIdList' , statusIdList)
  Api.GetEndUserJobListWeb(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetEndUserJobNotesList = async function (
  authToken,
  linkHash,
  start,
  length,
  requestType,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', authToken)
  data.append('linkHash' , linkHash)
  data.append('start' , start)
  data.append('length' , length)
  data.append('requestType' , requestType)
  Api.GetEndUserJobNotesList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetEndUserQuoteList = async function (
  authToken,
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  statusIdList,
  from,
  to,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', authToken)
  data.append('start' , start)
  data.append('length' , length)
  data.append('searchVal' , searchVal)
  data.append('shortBy' , shortBy)
  data.append('sortOrder' , sortOrder)
  data.append('statusIdList' , statusIdList)
  data.append('from' , from)
  data.append('to' , to)
  Api.GetEndUserQuoteList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetEndUserInvoiceList = async function (
  authToken,
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  statusIdList,
  from,
  to,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', authToken)
  data.append('start' , start)
  data.append('length' , length)
  data.append('searchVal' , searchVal)
  data.append('shortBy' , shortBy)
  data.append('sortOrder' , sortOrder)
  data.append('statusIdList' , statusIdList)
  data.append('from' , from)
  data.append('to' , to)
  Api.GetEndUserInvoiceList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetEndUserVisitList = async function (
  authToken,
  jobLinkHash,
  start,
  length,
  filterType,
  from,
  to,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', authToken)
  data.append('jobLinkHash' , jobLinkHash)
  data.append('start' , start)
  data.append('length' , length)
  data.append('filterType' , filterType)
  data.append('from' , from)
  data.append('to' , to)
  Api.GetEndUserVisitList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetEndUserPaymentList = async function (
  authToken,
  start,
  length,
  searchVal,
  invoiceLinkHash,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', authToken)
  data.append('start' , start)
  data.append('length' , length)
  data.append('searchVal' , searchVal)
  data.append('invoiceLinkHash' , invoiceLinkHash)
  Api.GetEndUserPaymentList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SendEndUserOtp = async function (
  authToken,
  emailId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', authToken)
  data.append('emailId' , emailId)
  Api.SendEndUserOtp(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.reSendEndUserOtp = async function (
  oToken,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('oToken', oToken)
  Api.reSendEndUserOtp(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.VarifyAddedOTPEndUSer = async function (
  authToken,
  oToken,
  otp,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', authToken)
  data.append('oToken', oToken)
  data.append('otp', otp)
  Api.VarifyAddedOTPEndUSer(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.AddDeleteCheckListInVisit = async function (
  visitCheckListDetail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('visitCheckListDetail', JSON.stringify(visitCheckListDetail))
  Api.AddDeleteCheckListInVisit(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.EmailVarifyLogin = async function (
  token,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('token', token)
  Api.EmailVarifyLogin(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.otpVarify = async function (
  token,
  otp,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('token', token)
  data.append('otp', otp)
  Api.otpVarify(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.ChangeUserEmail = async function (
  emailAddress,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('emailAddress', emailAddress)
  Api.ChangeUserEmail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetPhoneCountryCode = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  Api.GetPhoneCountryCode(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetIndustryDetail = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  Api.GetIndustryDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.DeleteAttachmentWeb = async function (
  deletedAttachment,
  excludeDeleteAttachment,
  isDeleteAll,
  requestType,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('deletedAttachment', deletedAttachment)
  data.append('excludeDeleteAttachment', excludeDeleteAttachment)
  data.append('isDeleteAll', isDeleteAll)
  data.append('requestType', requestType)
  Api.DeleteAttachmentWeb(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}































// Get rquest

MyJobModal.prototype.DashboardOverview = async function (
  from,
  to,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&from=' + from + '&to=' + to
  Api.DashboardOverview(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.DashboardDetailApi = async function (
  currentDate,
  fromDate,
  toDate,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&currentDate=' + currentDate + '&fromDate=' + fromDate + '&toDate=' + toDate
  Api.DashboardDetailApi(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.DashboardAssignmentListApi = async function (
  currentDate,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&currentDate=' + currentDate
  Api.DashboardAssignmentListApi(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.DashboardTaskListApi = async function (
  currentDate,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&currentDate=' + currentDate
  Api.DashboardTaskListApi(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getPyamentDetail = async function (
  paymentId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&paymentId=' + paymentId
  Api.getPyamentDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getCustBasicDetail = async function (
  customerId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&customerId=' + customerId
  Api.getCustBasicDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getPaymentDetail = async function (
  invoiceId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&invoiceId=' + invoiceId
  Api.getPaymentDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getLeadStatusList = async function (
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken')
  Api.getLeadStatusList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getReqStatusList = async function (
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken')
  Api.getReqStatusList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getQuotationStatusList = async function (
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken')
  Api.getQuotationStatusList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.paymentDetailForEdit = async function (
  paymentId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&paymentId=' + paymentId
  Api.paymentDetailForEdit(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.deletePayment = async function (
  paymentId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&paymentId=' + paymentId
  Api.deletePayment(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.deleteInvoice = async function (
  invoiceId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&invoiceId=' + invoiceId
  Api.deleteInvoice(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getEntityList = async function (
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken')
  Api.getEntityList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getEmployeeList = async function (
  filter,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&filter=' + filter
  Api.getEmployeeList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getJobDetail = async function (
  jobId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&jobId=' + jobId
  Api.getJobDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getvisitLIst = async function (
  jobId,
  filterType,
  start,
  length,
  userDetailIdList,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&jobId=' + jobId + '&filterType=' + filterType + '&start=' + start + '&length=' + length + '&userDetailIdList=' + userDetailIdList
  Api.getvisitLIst(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getNotesLIst = async function (
  requestType,
  requestId,
  start,
  length,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&requestType=' + requestType + '&requestId=' + requestId + '&start=' + start + '&length=' + length 
  Api.getNotesLIst(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getFullImage = async function (
  fileVaultId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&fileVaultId=' + fileVaultId
  Api.getFullImage(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getSettingItemsDetail = async function (
  itemId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&itemId=' + itemId
  Api.getSettingItemsDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getSettingEmployeeList = async function (
  filter,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&filter=' + filter
  Api.getSettingEmployeeList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getSettingEmployeeDetail = async function (
  userDetailId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&userDetailId=' + userDetailId
  Api.getSettingEmployeeDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getSendBookConfData = async function (
  entitySlug,
  detailId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&entitySlug=' + entitySlug + '&detailId=' + detailId
  Api.getSendBookConfData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getVisitDetail = async function (
  visitId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&visitId=' + visitId
  Api.getVisitDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getVisitItemList = async function (
  visitId,
  jobId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&visitId=' + visitId + '&jobId=' + jobId
  Api.getVisitItemList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

MyJobModal.prototype.specificShiftDetail = async function (
  timeSheetId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&timeSheetId=' + timeSheetId
  Api.specificShiftDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

MyJobModal.prototype.getSpecificShiftLocation = async function (
  shiftId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&shiftId=' + shiftId
  Api.getSpecificShiftLocation(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getBreakList = async function (
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken')
  Api.getBreakList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getJobLocation = async function (
  visitId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&visitId=' + visitId
  Api.getJobLocation(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getLogedTIme = async function (
  userIds,
  visitId,
  jobId,
  exportData,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&userIds=' + userIds + '&visitId=' + visitId + '&jobId=' + jobId + '&exportData=' + exportData
  Api.getLogedTIme(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getJobTimeLogUsers = async function (
  jobId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&jobId=' + jobId
  Api.getJobTimeLogUsers(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getOrgDetail = async function (
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken')
  Api.getOrgDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetLeadDetail = async function (
  leadId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&leadId=' + leadId
  Api.GetLeadDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetBasicLeadDetail = async function (
  leadId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&leadId=' + leadId
  Api.GetBasicLeadDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getLeadCustEmailNumCheck = async function (
  reqType,
  value,
  contactId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&reqType=' + reqType + '&value=' + value + '&contactId=' + contactId
  Api.getLeadCustEmailNumCheck(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getOrgQrCode = async function (
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken')
  Api.getOrgQrCode(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getUnscheduleDetail = async function (
  jobId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&jobId=' + jobId
  Api.getUnscheduleDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getRequestDetail = async function (
  requestId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&requestId=' + requestId
  Api.getRequestDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetTaskDetail = async function (
  taskId,
  taskSelectedDate,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&taskId=' + taskId + '&taskSelectedDate=' + taskSelectedDate
  Api.GetTaskDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetPlanListing = async function (
  currencyMasterId,
  paymentFrequency,
  callBackFunc,
  onError
) {
  let data = '?currencyMasterId=' + currencyMasterId + '&paymentFrequency=' + paymentFrequency 
  Api.GetPlanListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetPlanCurrencyList = async function (
  isFree,
  callBackFunc,
  onError
) {
  let data = '?isFree=' + isFree
  Api.GetPlanCurrencyList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetLogedInEmployeePermissions = async function (
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken')
  Api.GetLogedInEmployeePermissions(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetUsedStorageDetail = async function (
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken')
  Api.GetUsedStorageDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetCurrentPlanDetail = async function (
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken')
  Api.GetCurrentPlanDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SettingCheckListItemDetail = async function (
  checkListItemMasterId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&checkListItemMasterId=' + checkListItemMasterId 
  Api.SettingCheckListItemDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetJobDetailLinkHash = async function (
  authToken,
  linkHash,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + authToken + '&linkHash=' + linkHash 
  Api.GetJobDetailLinkHash(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetQuoteDetailLinkHash = async function (
  authToken,
  linkHash,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + authToken + '&linkHash=' + linkHash 
  Api.GetQuoteDetailLinkHash(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetInvoiceDetailLinkHash = async function (
  authToken,
  linkHash,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + authToken + '&linkHash=' + linkHash 
  Api.GetInvoiceDetailLinkHash(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetVisitDetailLinkHash = async function (
  authToken,
  linkHash,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + authToken + '&linkHash=' + linkHash 
  Api.GetVisitDetailLinkHash(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.TokenVerified = async function (
  authToken,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + authToken
  Api.TokenVerified(data).then(data => {
    console.log('datadatadatadata', data)
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetChecklistInVisitDetail = async function (
  visitId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&visitId=' + visitId
  Api.GetChecklistInVisitDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetAttachmentOverview = async function (
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken')
  Api.GetAttachmentOverview(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetAttachmentList = async function (
  start,
  length,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&start=' + start + '&length=' + length
  Api.GetAttachmentList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.ShiftRealTimeView = async function (
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken')
  Api.ShiftRealTimeView(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

MyJobModal.prototype.GetToolsList = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  Api.GetToolsList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.updateVisitToolPicWeb = async function (
  toolDetails,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('toolDetails', JSON.stringify(toolDetails))
  Api.updateVisitToolPicWeb(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SaveToolsData = async function (
  toolDetail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('toolDetail', JSON.stringify(toolDetail))
  Api.SaveToolsData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

MyJobModal.prototype.DeleteToolDetail = async function (
  toolMasterId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('toolMasterId', toolMasterId)
  Api.DeleteToolDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

MyJobModal.prototype.JobCategorySave = async function (
  categoryDetail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('categoryDetail', JSON.stringify(categoryDetail))
  Api.JobCategorySave(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

MyJobModal.prototype.JobCategoryList = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  Api.JobCategoryList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

MyJobModal.prototype.DeleteJobCatagory = async function (
  jobCategoryId,
  newCategoryId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('jobCategoryId', jobCategoryId)
  data.append('newCategoryId', newCategoryId)
  Api.DeleteJobCatagory(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

MyJobModal.prototype.GetNotesCategories = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  Api.GetNotesCategories(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

MyJobModal.prototype.AddUpdateNoteCategory = async function (
  categoryId,
  categoryName,
  isDefault,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('categoryId', categoryId)
  data.append('categoryName', categoryName)
  data.append('isDefault', isDefault)
  Api.AddUpdateNoteCategory(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

MyJobModal.prototype.DeleteNoteCategory = async function (
  deleteCategoryId,
  newCategoryId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('deleteCategoryId', deleteCategoryId)
  data.append('newCategoryId', newCategoryId)
  Api.DeleteNoteCategory(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

MyJobModal.prototype.UpdateNoteCategoryOrder = async function (
  categoryList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('categoryList', JSON.stringify(categoryList))
  Api.UpdateNoteCategoryOrder(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

MyJobModal.prototype.GetJobTypeList = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  Api.GetJobTypeList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

MyJobModal.prototype.SaveJobTypeDetail = async function (
  jobTypeDetail,
  jobCheckList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('jobTypeDetail', JSON.stringify(jobTypeDetail))
  data.append('jobCheckList', JSON.stringify(jobCheckList))
  Api.SaveJobTypeDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetEmailConfDetail = async function (
  emailConfigId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('emailConfigId', emailConfigId)
  Api.GetEmailConfDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.TwilioConfigDetail = async function (
  twilioConfigurationId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('twilioConfigurationId', twilioConfigurationId)
  Api.TwilioConfigDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetPhoneBasicDetail = async function (
  reqType,
  requestId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('reqType', reqType)
  data.append('requestId', requestId)
  Api.GetPhoneBasicDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.getSmsList = async function (
  start,
  length,
  searchVal,
  reqType,
  detailId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('reqType', reqType)
  data.append('detailId', detailId)
  Api.getSmsList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SendTextMessage = async function (
  reqType,
  detailId,
  customerId,
  twilioConfigurationId,
  message,
  sendToList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('reqType', reqType)
  data.append('detailId', detailId)
  data.append('customerId', customerId)
  data.append('twilioConfigurationId', twilioConfigurationId)
  data.append('message', message)
  data.append('sendToList', JSON.stringify(sendToList))
  Api.SendTextMessage(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.TwilioConfigSave = async function (
  reqObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('reqObj', JSON.stringify(reqObj))
  Api.TwilioConfigSave(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.GetTwilioList = async function (
  start,
  length,
  searchValue,
  sortColumnName,
  sortColumnOrder,
  reqType,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchValue', searchValue)
  data.append('sortColumnName', sortColumnName)
  data.append('sortColumnOrder', sortColumnOrder)
  data.append('reqType', reqType)
  Api.GetTwilioList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.EmailConfList = async function (
  start,
  length,
  searchValue,
  sortColumnName,
  sortColumnOrder,
  isActive,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchValue', searchValue)
  data.append('sortColumnName', sortColumnName)
  data.append('sortColumnOrder', sortColumnOrder)
  data.append('isActive', isActive)
  Api.EmailConfList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SaveEmailConf = async function (
  emailConfig,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('emailConfig', JSON.stringify(emailConfig))
  Api.SaveEmailConf(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}
MyJobModal.prototype.SendTestEmail = async function (
  emailConfig,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('emailConfig', JSON.stringify(emailConfig))
  Api.SendTestEmail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

MyJobModal.prototype.CheckJobTypeLinked = async function (
  jobTypeId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('jobTypeId', jobTypeId)
  Api.CheckJobTypeLinked(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

MyJobModal.prototype.DeleteJobType = async function (
  jobTypeId,
  newJoTypeId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('jobTypeId', jobTypeId)
  data.append('newJoTypeId', newJoTypeId)
  Api.DeleteJobType(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}


MyJobModal.prototype.GetManageStorageOverviewDetail = async function (
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken')
  Api.GetManageStorageOverviewDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

MyJobModal.prototype.GetAllAttachment = async function (
  start,
  length,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('jobAuthToken') + '&start=' + start + '&length=' + length
  Api.GetAllAttachment(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

MyJobModal.prototype.invoiceListOfVisitsAndJobWeb = async function (
  start,
  length,
  jobId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('jobAuthToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('jobId', jobId)
  Api.invoiceListOfVisitsAndJobWeb(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      const findex = errorCode.findIndex(x => x.errorCode === Number(data.message))
      if (findex > -1) {
        data.message = errorCode[findex].message
        onError(data)
      }  else {
        onError(data)
      }
    }
  })
}

export default MyJobModal
